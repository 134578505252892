import {createGetRequest, getSpinner, includeAsyncScript} from "./helpers";

/*
    Simple wrapper for handling Stripe
    Usage
    1) initialize
    2) mountFrontend
    3) submitPayment
 */
export class StripeClient {
    #client;
    #publicKey;
    #card;
    #form;
    #container;
    #errorContainer;
    
    appearance = {
        theme: 'flat',
        variables: { colorPrimaryText: '#262626' }
    };
    
    constructor(client, publicKey) {
        this.#client = client;
        this.#publicKey = publicKey;
    }
    
    static async initialize(publicKey) {
        await includeAsyncScript('https://js.stripe.com/v3/');
        const client = window.Stripe(publicKey);
        return new StripeClient(client, publicKey);
    }
    async mountFrontend (stripeForm, containerElement, errorElement) {
        try {
            this.#form = stripeForm;
            this.#container = containerElement;
            this.#errorContainer = errorElement;
            
            const elements = this.#client.elements();

            const style = {
                base: {
                    color: '#000000',
                    fontSize: '16px',
                    padding: '10px',
                    '::placeholder': {
                        color: '#CCCCCC'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };

            // Create an instance of the card Element.
            this.#card = elements.create('card', {style});
            this.#card.mount(this.#container);

            // Handle real-time validation errors from the card Element.
            this.#card.addEventListener('change', (event) => {
                if (event.error) {
                    this.#errorContainer.innerHTML = `<span>${event.error.message}</span>`;
                } else {
                    this.#errorContainer.innerHTML = '';
                }
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    async submitPayment(){
        const spinner = await getSpinner();
        const paymentDataResponse = await createGetRequest('/stripe/get-payment-data');
        if( paymentDataResponse.success && paymentDataResponse.hasOwnProperty('data') ) {
            const paymentData = paymentDataResponse.data;
            try {
                const paymentResult = await this.#client.createPaymentMethod('card', this.#card, paymentData);
                if( paymentResult.hasOwnProperty('error') && paymentResult.error.hasOwnProperty('message') ) {
                    throw paymentResult.error;
                }
                spinner.show();
                const hiddenInput = document.createElement('input');
                hiddenInput.setAttribute('type', 'hidden');
                hiddenInput.setAttribute('name', 'paymentForm[stripe][paymentMethodId]');
                hiddenInput.setAttribute('value', paymentResult.paymentMethod.id);
                this.#form.appendChild(hiddenInput);
                this.#form.submit();
            } catch (error) {
                const message = error.hasOwnProperty('message') ? error.message : 'There was an error while processing your request.';
                this.#errorContainer.innerHTML = `<span>${message}</span>`;
                console.error(message);
                // this might not take effect (hiding spinner), but since we are checking if there is any kind of validation error before submitting the form, we should be ok
                spinner.hide();
            }
        }
    }
}