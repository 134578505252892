import {addDynamicEventListener, createPostRequest, getClosest} from "./helpers";
import {Modal} from 'bootstrap';
import {Swiper, Navigation} from "swiper";
import {GTMAddItemToCart, GTMViewItem, GTMViewItemList} from "./analytics";

let productContainer;
let parentContainer;
let productOptionsContainer;
let swiper2Col;
let swiper4Col;
let accessoryProductsContainer;
let recommendedProductsContainer;
export function initProduct() {
    document.addEventListener('DOMContentLoaded', () => {
        productContainer = document.querySelector('.block__shop__product');
        productOptionsContainer = document.querySelector('div.product-options');
        accessoryProductsContainer = document.querySelector('.list__product[data-list-id="accessory_products"]');
        recommendedProductsContainer = document.querySelector('.list__product[data-list-id="recommended_products"]');
        if( productContainer instanceof HTMLElement) {
            parentContainer = productContainer.parentNode;
            init();
            // GTM view_item
            GTMViewItem(productContainer.dataset.variantId);
        }
        
        if( accessoryProductsContainer instanceof HTMLElement ) {
            const items = accessoryProductsContainer.querySelectorAll('article');
            if( items.length ) {
                let purchasableIds = [];
                items.forEach(article => purchasableIds.push(article.dataset.purchasableId ?? null));
                purchasableIds = purchasableIds.filter(v => v !== null);
                if( purchasableIds.length ) {
                    // GTM view_item_list
                    GTMViewItemList('accessory_products', 'Accessory products', purchasableIds);
                }
            }
        }
        
        if( recommendedProductsContainer instanceof HTMLElement ) {
            const items = recommendedProductsContainer.querySelectorAll('article');
            if( items.length ) {
                let purchasableIds = [];
                items.forEach(article => purchasableIds.push(article.dataset.purchasableId ?? null));
                purchasableIds = purchasableIds.filter(v => v !== null);
                if( purchasableIds.length ) {
                    // GTM view_item_list
                    GTMViewItemList('recommended_products', 'Recommended products', purchasableIds);
                }
            }
        }
    });
}

const updateOptionOrder = () => {
    const modals = document.querySelectorAll('[id^="offcanvasOption"]');
    [...modals].map(modal => {
        const ul = modal.querySelector('ul');
        if( ul instanceof HTMLElement ) {
            const anchors = ul.querySelectorAll('a');
            const sorted = [...anchors].sort((a,b) => {
                if( a.classList.contains('disabled') && b.classList.contains('disabled') ) {
                    return 0;
                } else if( a.classList.contains('disabled') ) {
                    return 1;
                } else {
                    return -1;
                }
            });

            [...ul.querySelectorAll('li')].forEach(listItem => listItem.parentNode.removeChild(listItem));
            sorted.map(anchor => {
                const li = document.createElement('LI');
                li.appendChild(anchor);
                ul.appendChild(li);
            });
        }
    });
}
const updateCategoryOptionVisibility = (selectedCategory) => {
    if( selectedCategory.hasOwnProperty('mainCategory') && selectedCategory.hasOwnProperty('selectedCategory') ) {
        const selectedMainCategoryId = parseInt(selectedCategory.mainCategory);
        const selectedCategoryId = parseInt(selectedCategory.selectedCategory);
        const relatedCategoryIds = getRelatedCategoryIds(null, selectedCategoryId);
        const options = document.querySelectorAll('[id^="offcanvasOption"] a');
        if( options.length ) {
            [...options].map(option => {
                if( option.hasAttribute('data-main-category-id') && option.hasAttribute('data-category-id') ) {
                    const mainCategoryId = parseInt(option.dataset.mainCategoryId);
                    const categoryId = parseInt(option.dataset.categoryId);
                    if( mainCategoryId !== selectedMainCategoryId ) {
                        if( !relatedCategoryIds.includes(categoryId) ) {
                            option.classList.add('disabled');
                        } else {
                            option.classList.remove('disabled');
                        }
                    }
                } else {
                    console.error(`${option.value} doesn't have necessary data attributes!`);
                }
            });
        }
    } else {
        console.error("Input parameter doesn't have required attributes!");
    }
}

const updateAllCategoryOptionVisibility = () => {
    const selectedCategories = getSelectedCategoryOptions();
    for( let i = 0; i < selectedCategories.length; i++ ) {
        updateCategoryOptionVisibility(selectedCategories[i]);
    }
    updateOptionOrder();
}

async function init() {
    addDynamicEventListener(parentContainer, 'click', '.product-meta .product-variants li', variantClickHandler );
    addDynamicEventListener(parentContainer, 'click', '#offcanvas__selectVariant li', variantClickHandler );
    addDynamicEventListener(parentContainer, 'click','.product-cta__add-to-cart button', addToCartHandler);
    
    initEventHandlersForPageRefresh();
    
    // Event handler for offcanvas variant selection
    if( productOptionsContainer !== null ) {
        updateAllCategoryOptionVisibility();
        
        addDynamicEventListener(document.body, 'click','[id^="offcanvasOption"] a', async (e) => {
            const target = e.target.nodeName === 'A' ? e.target : getClosest(e.target, 'a');
            if( target instanceof HTMLElement ) {
                e.preventDefault();
                const modal = getClosest(target, 'div.offcanvas');
                modal.querySelector('button.bttn--close').click();
                
                // don't refresh page when already selected options is being clicked
                if( target.classList.contains('selected') ) {
                    return;
                }

                [...target.closest('ul').querySelectorAll('a')].map(el => {
                    el.classList.remove('selected');
                    if( el.dataset.categoryId === target.dataset.categoryId ) {
                        el.classList.add('selected');
                    }
                });
                
                const selectedCategory = target.dataset.categoryId;
                const selectedMainCategory =  modal.dataset.mainCategoryId;
                const categoryName = target.querySelector('.product-option__title').textContent.trim();
                const configTextContainer = productOptionsContainer.querySelector(`button[data-bs-target="#${modal.id}"] .option__value__text`);

                if( configTextContainer instanceof HTMLElement ) {
                    configTextContainer.textContent = categoryName;
                }
                
                const selectedCategoryOptions = updateSelectedCategoryOptions(selectedMainCategory, selectedCategory);

                const response = await createPostRequest('/webshop/get-product-template', {productId: productContainer.dataset.productId, selectedCategoryOptions, selectedOption: selectedCategory});
                if( response.success ) {
                    updateProductContainer(response.response.productTemplate);
                }
            }
        });
    }
}

const initEventHandlersForPageRefresh = () => {
    initSwipers();
    initProductGallery();
    initQuantityButtons();
    initToggleShowMoreText();
}

const initToggleShowMoreText = () => {
    // Toggles a class on click to show/hide text
    let button = document.querySelector('.toggle-text');
    let productDescription = document.querySelector('.product-description');
    if( button instanceof HTMLElement && productDescription instanceof HTMLElement ) {
        button.addEventListener('click', () => {
            productDescription.classList.toggle('more');
            if (button.classList.contains('hide-after-open')) {
                button.classList.add('d-none');
            }
        }); 
    }
}

const initQuantityButtons = () => {
    const addBtn = document.body.querySelector('button.qty-add');
    const subBtn = document.body.querySelector('button.qty-sub');
    
    if( addBtn instanceof HTMLElement && subBtn instanceof HTMLElement ) {
        addBtn.addEventListener('click', () => {
            const input = addBtn.previousElementSibling;
            const qty = parseInt(input.value);
            const maxQty = parseInt(input.getAttribute('max'));
            if( qty + 1 > maxQty ) {
                return;
            }
            input.value = qty + 1;
        });
        
        subBtn.addEventListener('click', () => {
            const input = subBtn.nextElementSibling;
            const qty = parseInt(input.value);
            
            if( qty > 1 ) {
                input.value = qty - 1;
            }
        });
    }
};

const updateProductContainer = (templateHTML) => {
    productContainer = document.querySelector('.block__shop__product');
    const recommendedProducts = document.querySelector('.recommended-products');
    if( recommendedProducts instanceof HTMLElement ) {
        recommendedProducts.remove();
    }
    productContainer.remove();
    parentContainer.insertAdjacentHTML('afterbegin', templateHTML);
    productContainer = document.querySelector('.block__shop__product');

    initEventHandlersForPageRefresh();
    updateAllCategoryOptionVisibility();
}

const updateSelectedCategoryOptions = (mainCategoryId, selectedCategoryId) => {
    const options = getSelectedCategoryOptions();
    options.forEach(option => {
        if( option.mainCategory === mainCategoryId ) {
            option.selectedCategory = selectedCategoryId;
        }
    });
    return options;
}

const getSelectedCategoryOptions = () => {
    return [...document.querySelectorAll('.product-options li')].map(li => {
        return {
            mainCategory: li.dataset.mainCategoryId,
            selectedCategory: li.dataset.selectedCategoryId
        }
    });
}

const getRelatedCategoryIds= (selectedAnchor = null, categoryId = null) => {
    let anchor = null;
    let relatedIds = [];
    
    if( selectedAnchor instanceof HTMLElement ) {
        anchor = selectedAnchor;
    } else if( categoryId ) {
        anchor = document.querySelector(`a[data-category-id="${categoryId}"]`);
    }
    
    if( anchor instanceof HTMLElement && anchor.hasAttribute("data-related-category-ids") ) {
        try {
            relatedIds = JSON.parse(anchor.dataset.relatedCategoryIds);
        } catch (e) {
            console.error(e);
        }
    }
    return relatedIds;
}

// event handler for out of stock modal
const addToCartHandler = async (e) => {
    const target = e.target.nodeName === 'BUTTON' ? e.target : getClosest(e.target, '.product-cta__add-to-cart button');
    if( target instanceof HTMLElement) {
        e.preventDefault();
        e.stopPropagation();
        if( target.classList.contains('out-of-stock') ) {
            const modalId = target.dataset.modalId;
            const modal = document.getElementById(modalId);
            if( modal instanceof HTMLElement ) {
                const bModal = new Modal(modal, {});
                bModal.show();
            }
        } else {
            const form = getClosest(target, 'form');
            if( form instanceof HTMLFormElement ) {
                const purchasableIdInput = form.querySelector('input[name="purchasableId"]');
                const qtyInput = form.querySelector('input[name="qty"]');
                if( purchasableIdInput instanceof HTMLInputElement && qtyInput instanceof HTMLInputElement ) {
                    // Send GTM conversion
                    await GTMAddItemToCart(purchasableIdInput.value, parseInt(qtyInput.value));
                    form.submit();
                }
            }
        }
    }
}

const variantClickHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target.nodeName === 'LI' ? e.target : getClosest(e.target, '.variant-list-item');
    if( target instanceof HTMLElement) {
        
        // close modal before updating DOM
        if( target.dataset.isOffcanvas === '1' ) {
            const modal = document.getElementById('offcanvas__selectVariant');
            if( modal !== null ) {
                const closeBtn = modal.querySelector('.bttn--close');
                if( closeBtn !== null ) {
                    closeBtn.click();
                }
            }
        }

        const variantId = target.dataset.variantId;
        if( variantId !== 'undefined' ) {
            const response = await createPostRequest('/webshop/get-product-template', {variantId});
            if( response.success ) {
                updateProductContainer(response.response.productTemplate);
                history.replaceState({}, '', target.querySelector('a').href);
                // send GTM view_item event
                GTMViewItem(productContainer.dataset.variantId);
            }
        }
    }
}

const initSwipers = () => {
    const swiper2ColPrevElement = document.querySelector('.block__shop__product__swipe--two-col .custom-prev');
    const swiper2ColNextElement = document.querySelector('.block__shop__product__swipe--two-col .custom-next');

    const swiper4ColPrevElement = document.querySelector('.block__shop__product__swipe--four-col .custom-prev');
    const swiper4ColNextElement = document.querySelector('.block__shop__product__swipe--four-col .custom-next');
    
    const swiper2ColElement = document.querySelector('.block__shop__product__swipe--two-col .swiper');
    const swiper4ColElement = document.querySelector('.block__shop__product__swipe--four-col .swiper');
    
    if( swiper2ColElement instanceof HTMLElement ) {
        if( swiper2Col instanceof Swiper) {
            swiper2Col.destroy();
        }
        if( swiper2ColPrevElement instanceof HTMLElement && swiper2ColNextElement instanceof HTMLElement ) {
            swiper2Col = new Swiper(swiper2ColElement, {
                modules: [Navigation],
                slidesPerView: 1,
                spaceBetween: 20,
                breakpoints: {
                    "576": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    "768": {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    "991": {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    "1200": {
                        slidesPerView: 2,
                    },
                },
                navigation: {
                    nextEl: swiper2ColNextElement,
                    prevEl: swiper2ColPrevElement
                },
            });
        }
    }
    
    if( swiper4ColElement instanceof HTMLElement ) {
        if( swiper4Col instanceof Swiper ) {
            swiper4Col.destroy();
        }
        if( swiper4ColPrevElement instanceof HTMLElement && swiper4ColNextElement instanceof HTMLElement ) {
            swiper4Col = new Swiper(swiper4ColElement, {
                modules: [Navigation],
                slidesPerView: 1,
                spaceBetween: 20,
                breakpoints: {
                    "576": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    "768": {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    "991": {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    "1200": {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: swiper4ColNextElement,
                    prevEl: swiper4ColPrevElement,
                },
            });
        }
    }
}

const initProductGallery = () => {
    // Magnific Popup Lightbox - Product page
    $('.product-image__wrapper').each(function () { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            mainClass: 'mfp-with-zoom',
            zoom: {
                enabled: true,
                duration: 150,
                easing: 'ease-in-out',
                opener: function (openerElement) {
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            },
            tLoading: 'Laster...', // Text that is displayed during loading. Can contain %curr% and %total% keys
            gallery: {
                enabled: true,
                tPrev: 'Forrige (Venstre piltast)', // Alt text on left arrow
                tNext: 'Neste (Høyre piltast)', // Alt text on right arrow
                tCounter: 'Bilde %curr% av %total%'
            }
        });
    });

    
    const productimagesXs = document.querySelector(".product-image-xs .product-image__wrapper");
    const btnMore = document.getElementById("load-more");
    const btnLess = document.getElementById("load-less");
    const imageMain = document.querySelector(".product-image-xl");
    const xlgridItems = document.querySelectorAll(".product-image-xl .product-image__wrapper a");
    const xsgridItems = document.querySelectorAll(".product-image-xs .product-image__wrapper a");
    let container = document.querySelector('.product-image__wrapper');
    
    // Checks if mobile product page image gallery contains more than one image
    if ( productimagesXs instanceof HTMLElement && xsgridItems.length > 1 ) {
        productimagesXs.classList.add("more-images");
    }
    
    // Checks if desktop product page image gallery contains more than four images
    if ( btnMore instanceof HTMLElement && xlgridItems.length > 4 ) {
        btnMore.style.display = "block";
    }

    // Adds a class if the number of product images is odd
    if (container instanceof HTMLElement) {
        // 2. Count all child elements
        let numElements = container.children.length;

        // 3. If the number of child elements is odd
        if (numElements % 2 === 1) {
            // Locate the div with the class .product-image-xl and add the .odd class to it
            if (imageMain instanceof HTMLElement) {
                imageMain.classList.add('odd');
            }
        }
    }
    
    // Adds a class to the image gallery container on click
    if( imageMain instanceof HTMLElement && btnMore instanceof HTMLElement && btnLess instanceof HTMLElement ) {
        btnMore.addEventListener("click", () => imageMain.classList.add("show--all"));
        btnLess.addEventListener("click", () => imageMain.classList.remove("show--all"));
    }
}