<template>
    <div class="accordion-item">
        <h2 class="accordion-header" :id="'allFilters_heading_' + categoryNameKebab">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#allFilters_collapse_' + categoryNameKebab" aria-expanded="false"
                    :aria-controls="'allFilters_collapse_' + categoryNameKebab">
                {{ categoryName }}
            </button>
        </h2>
        <div :id="'allFilters_collapse_' + categoryNameKebab"
             class="accordion-collapse collapse"
             data-category-group-handle=""
             :aria-labelledby="'allFilters_heading_' + categoryNameKebab"
             data-bs-parent="#allFilters">
            <div class="accordion-body">
                <FilterSizeSlider v-if="sizes.height && sizes.height.max > 0"
                                  :title="translations.height"
                                  :filter-key="'height'"
                                  :min-max-obj="sizes.height"
                                  :max-value="sizes.height.max"
                                  @value-update="sliderUpdated"></FilterSizeSlider>
                <FilterSizeSlider v-if="sizes.width && sizes.width.max > 0"
                                  :title="translations.width"
                                  :filter-key="'width'"
                                  :min-max-obj="sizes.width"
                                  :max-value="sizes.width.max"
                                  @value-update="sliderUpdated"></FilterSizeSlider>
                <FilterSizeSlider v-if="sizes.length && sizes.length.max > 0"
                                  :title="translations.length"
                                  :filter-key="'length'"
                                  :min-max-obj="sizes.length"
                                  :max-value="sizes.length.max"
                                  @value-update="sliderUpdated"></FilterSizeSlider>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineComponent, onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { kebab } from "../helpers";
import mitt from "mitt";
import noUiSlider from "nouislider";
import FilterSizeSlider from "./FilterSizeSlider.vue";

defineComponent({
    name: "FilterSizes"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const translations = window.lindbakVueTranslations;
const props = defineProps(['sizes', 'categoryName', 'filterName']);
const emit = defineEmits(['sizesChange']);
const categoryNameKebab = ref('product-filter__');
const selectedSizes = ref({});

onBeforeMount(() => {
    categoryNameKebab.value = kebab(props.categoryName);
});
onMounted(() => {
    emitter.on('selectedSizesUpdated', selectedSizesUpdated);
});
onUnmounted(() => {
    emitter.off('selectedSizesUpdated', selectedSizesUpdated);
});

const emitFilterChange = () => {
    emit('sizesChange', selectedSizes.value);
};

const selectedSizesUpdated = sizes => {
    selectedSizes.value = sizes
    emitFilterChange();
};
const sliderUpdated = ({ key, value }) => {
    selectedSizes.value[key] = value;
    emitFilterChange();
};

</script>