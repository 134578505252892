import L from "leaflet";
export function initMaps() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const maps = document.querySelectorAll('.map-lazyload');

    for( const map of maps ) {
        if( !map.dataset.lat || !map.dataset.lng ) {
            continue;
        }
        const m = L.map(map.id).setView([map.dataset.lat, map.dataset.lng], map.dataset.zoom || 15);
    }
}