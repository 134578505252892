import {clearLindbakGTMData, getLindbakGTMData, GTMPurchase, setLindbakGTMData} from "./analytics";

let checkoutContainer;
export function initCheckoutStep3() {
    document.addEventListener('DOMContentLoaded', () => {
        checkoutContainer = document.querySelector('.checkoutStep--3');
        if( checkoutContainer instanceof HTMLElement ) {
            init();
        }
    });
}
async function init() {
    const isLandingAfterCheckout = checkoutContainer.dataset.isLandingAfterCheckout === '1';
    const orderId = checkoutContainer.dataset.orderId;
    const lindbakGTM = getLindbakGTMData();
    
    if( isLandingAfterCheckout ) {
        if( location.search.length ) {
            // remove "?checkout=1" from URL
            window.history.pushState({}, '', `${window.location.origin + window.location.pathname}`);
        }
        
        if( !lindbakGTM.purchase && orderId ) {
            // send GTM conversion
            await GTMPurchase(orderId);
            clearLindbakGTMData();
        }
    }
}