import {createPostRequest} from "./helpers";

export function initHeader() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    // Header Search Dropdown
    $("header .search input").focus(function () {
        $('html').addClass("header-search--open").addClass("noscroll").addClass("overlay");
        $('.nav--search .bttn--close').attr('aria-expanded', "true");
    });

    // Header search close
    $("header .nav--search .bttn--close").click(function () {
        $('html').removeClass("header-search--open").removeClass("noscroll").removeClass("overlay");
    });

    // Mobile Header Search Open
    $("header .site__header__left .nav--search a").click(function (e) {
        $('html').addClass("header-search--open").addClass("noscroll").addClass("overlay");
        $('.nav--search .bttn--close').attr('aria-expanded', "true");
        e.preventDefault();
    });
    
    // Submenu > sub-submenu visibility
    // $('.menuSlidein--subMenu .nav-one a').on('click', function(e){
    //     const nodeId = this.dataset.nodeId;
    //     const $newSubmenu = $(this).closest('.menuSlidein--subMenu').find(`.nav-four[data-parent-id="${nodeId}"]`)
    //     $(this).parent().siblings().removeClass('active');
    //     if ($newSubmenu.length) {
    //         e.preventDefault();
    //         $(this).parent().addClass('active')
    //         $newSubmenu.removeClass('d-none').siblings().addClass('d-none');
    //     } else {
    //         $(this).closest('.menuSlidein--subMenu').find('.nav-four').addClass('d-none');
    //     }
    // });

    // Submenu > sub-submenu visibility on hover on desktop

    $('.menuSlidein--subMenu .nav-one li.has-ul > a').mouseenter(function() {
        const nodeId = $(this).data('nodeId');
        const $newSubmenu = $(this).closest('.menuSlidein--subMenu').find(`.nav-four[data-parent-id="${nodeId}"]`);
        if ($newSubmenu.length) {
            // Remove hover class from all .nav-four elements
            $('.nav-four').removeClass('hover');
            // Add hover class to the targeted .nav-four
            $newSubmenu.addClass('hover');
            // Add hover class to its parent li.has-ul
            $('li.has-ul').removeClass('hover');
            $(this).parent('li.has-ul').addClass('hover');
        }
    }).click(function() {
        // Toggle active class
        $(this).toggleClass('active');
        // Remove active class from other elements
        $('.menuSlidein--subMenu .nav-one li.has-ul > a').not(this).removeClass('active');

        // Check viewport width and prevent default if below 767 pixels
        if ($(window).width() <= 767) {
            event.preventDefault();
        }
    });
    
    // Handler for .menuSlidein--subMenu .nav-one li:not(.has-ul) > a
    $('.menuSlidein--subMenu .nav-one li:not(.has-ul) > a').mouseenter(function() {
        // Remove hover class from all .nav-four elements
        $('li.has-ul').removeClass('hover');
        $('.nav-four').removeClass('hover');
    });
    
    const currentEntryId = document.body.dataset.entryId;
    createPostRequest('/template/header-info', {
        currentEntryId: currentEntryId
    }).then(data => {
        if (data.success && data.response.success) {
            const responseData = data.response.data;
            const siteHeader = document.querySelector('header.site__header');
            if( siteHeader ) {
                
                // user icon url / my page
                if( Object.prototype.hasOwnProperty.call(responseData, 'userIconUrl') ) {
                    const navAccountLink = siteHeader.querySelector('.nav--account > a');
                    if( navAccountLink ) {
                        navAccountLink.href = responseData.userIconUrl;
                    }
                }

                // cart item indicator
                if( Object.prototype.hasOwnProperty.call(responseData, 'cartItemsCount') && responseData.cartItemsCount > 0) {
                    const navCart = siteHeader.querySelector('.nav--cart');
                    const navCartLink = siteHeader.querySelector('.nav--cart > a');
                    if( navCart && navCartLink ) {
                        navCart.classList.add('active');
                        navCartLink.setAttribute('aria-label', (responseData.cartItemsCount > 1 ? navCartLink.dataset.labelPlural : navCartLink.dataset.labelSingular).replace('%d', responseData.cartItemsCount))
                        const navCartLinkSpan = navCartLink.querySelector('span');
                        if( navCartLinkSpan ) {
                            navCartLinkSpan.textContent = responseData.cartItemsCount;
                            navCartLinkSpan.classList.remove('d-none');
                        }
                    }
                }

                // wishlist / favourites
                if( Object.prototype.hasOwnProperty.call(responseData, 'wishlistUrl') ) {
                    const favLink = siteHeader.querySelector('.nav--favourites > a');
                    if( favLink ) {
                        favLink.href = responseData.wishlistUrl;
                        if( Object.prototype.hasOwnProperty.call(responseData, 'wishlistItemsCount') && responseData.wishlistItemsCount > 0 ) {
                            favLink.setAttribute('aria-label', (responseData.wishlistItemsCount > 1 ? favLink.dataset.labelPlural : favLink.dataset.labelSingular).replace('%d', responseData.wishlistItemsCount));
                            const favLinkSpan = favLink.querySelector('span');
                            if( favLinkSpan ) {
                                favLinkSpan.classList.remove('d-none');
                                favLinkSpan.textContent = responseData.wishlistItemsCount;
                            }
                        }
                    }
                }

                // cp edit url for the entry
                if(
                    Object.prototype.hasOwnProperty.call(responseData, 'canUserEditEntry') &&
                    Object.prototype.hasOwnProperty.call(responseData, 'cpEditUrl')
                ) {
                    const cpEditButtonWrapper = siteHeader.querySelector('.admin-edit-button-wrapper');
                    if( cpEditButtonWrapper ) {
                        if(! responseData.canUserEditEntry ) {
                            cpEditButtonWrapper.classList.add('d-none');
                            cpEditButtonWrapper.classList.remove('d-lg-flex');
                        } else {
                            cpEditButtonWrapper.classList.add('d-lg-flex');
                            cpEditButtonWrapper.classList.remove('d-none');
                            const cpEditLink = cpEditButtonWrapper.querySelector('a.btn');
                            if( cpEditLink) {
                                cpEditLink.href = responseData.cpEditUrl;
                            }
                        }
                    }
                }
            }
            
            if( Object.prototype.hasOwnProperty.call(responseData, 'isUserLoggedIn') ) {
                const loginButton = document.querySelector('.offcanvas-header .nav--login .bttn--logIn');
                const logoutButton = document.querySelector('.offcanvas-header .nav--login .bttn--logOut');
                const navAccountButton = document.querySelector('.offcanvas-header .nav--account');

                if( responseData.isUserLoggedIn ) {
                    if( navAccountButton ) {
                        navAccountButton.classList.remove('d-none');
                    }
                    if( logoutButton ) {
                        logoutButton.classList.remove('d-none');
                    }
                } else {
                    if( loginButton ) {
                        loginButton.classList.remove('d-none');
                    }
                }
            }
            
            const taxSelectorContainer = document.querySelector('.block__breadcrumb nav.tax__selector');
            if( taxSelectorContainer && Object.prototype.hasOwnProperty.call(responseData, 'showTax') ) {
                const showTaxItem = taxSelectorContainer.querySelector('li.segment--priv');
                const noTaxItem = taxSelectorContainer.querySelector('li.segment--corp');
                if( showTaxItem && noTaxItem ) {
                    if( responseData.showTax ) {
                        showTaxItem.classList.add('active');
                        noTaxItem.classList.remove('active');
                    } else {
                        noTaxItem.classList.add('active');
                        showTaxItem.classList.remove('active');
                    }
                    taxSelectorContainer.classList.remove('d-none');
                }
            }
        }
    });
}