import { createApp } from "vue";
import ProductSwiperSlides from "./vue/ProductSwiperSlides.vue";
import ProductGrid from "./vue/ProductGrid.vue";
import Webshop from "./vue/Webshop.vue";
import WebshopFilters from "./vue/WebshopFilters.vue";

export function initVueApps() {
    initProductGrids();
    initWebshopApp();
    initWebshopFiltersApp();
}

function initProductGrids() {
    const productGrids = document.querySelectorAll('.row.list.list__product[data-vue-products]');
    for (const productGrid of productGrids) {
        try {
            const productVariantIds = productGrid.dataset.vueProducts ? JSON.parse(productGrid.dataset.vueProducts) : [];
            const loadMore = productGrid.dataset.loadMore === '1';
            createApp(ProductGrid, { productVariantIds, loadMore }).mount(productGrid);
        } catch (e) {
            // silent fail
        }
    }
}

// no need to init this here -- it is called from swiper.js
export function initSwiperVueApps(slider, productListBlocks) {
    if (productListBlocks.length) {
        productListBlocks.forEach(block => {
            const appWrapper = block.querySelector('.swiper-wrapper');
            try {
                const productVariantIds = appWrapper.dataset.vueProducts ? JSON.parse(appWrapper.dataset.vueProducts) : [];
                if (productVariantIds.length) {
                    createApp(ProductSwiperSlides, { productVariantIds }).mount(appWrapper);
                }
            } catch (e) {
                // silent fail
            }
        });
    }
}

function initWebshopApp() {
    const webshopRoot = document.querySelector('.block__shop__product__list .block__front[data-vue-app]');
    if (webshopRoot) {
        try {
            const hideWebshopHeader = webshopRoot.dataset.hideWebshopHeader ? webshopRoot.dataset.hideWebshopHeader === '1' : false;
            const title = webshopRoot.dataset.title.trim() || '';
            const categoryId = webshopRoot.closest('.block').dataset.productCategoryId;
            const categoryDescription = webshopRoot.dataset.categoryDescription || '';
            const csrfToken = webshopRoot.dataset.csrfToken || '';
            const noProductsText = webshopRoot.dataset.noProductsText || '';
            const showContractedProducts = webshopRoot.dataset.showContractedProducts === '1';
            createApp(Webshop, { hideWebshopHeader, title, categoryId, categoryDescription, csrfToken, showContractedProducts, noProductsText }).mount(webshopRoot);
        } catch (e) {
            // silent fail
        }
    }
}

function initWebshopFiltersApp() {
    const filtersRoot = document.querySelector('#offcanvasAllFilters .offcanvas-body [data-vue-app]');
    if (filtersRoot) {
        try {
            createApp(WebshopFilters, {}).mount(filtersRoot)
        } catch (e) {
            // silent fail
        }
    }
}

export const webshopFiltersMapping = {
    'colorIds': 'colors',
    'salesPitchIds': 'salesPitch',
    'segmentIds': 'segment',
    'environmentalDocumentationIds': 'environmentalAndQualityMarks',
    'areaOfUseIds': 'areaOfUse',
    'producerIds': 'producers',
    'productCategoryIds': 'productCategory',
};