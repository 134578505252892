<template>
    <div class="block__header">
        <div class="container">
            <div class="block__header__header">
                <WebshopHeader v-if="! hideWebshopHeader"
                               :title="title"
                               :category-description="categoryDescription">
                </WebshopHeader>
            </div>
            
            <div class="block__header__footer">
                <div class="block__header__wrapper">
                    <div class="block__header__wrapper__left">
                        <div class="block__header__filter">
                            <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasAllFilters"
                                    aria-controls="offcanvasAllFilters" class="bttn bttn--outline">
                                <span class="button-numb" v-if="totalActiveFilters > 0">{{ totalActiveFilters }}</span>
                                <span class="button-text">{{ translations.filter }}</span>
                                <span class="button-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path d="M0 416c0 8.8 7.2 16 16 16l65.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 432c8.8 0 16-7.2 16-16s-7.2-16-16-16l-257.6 0c-7.4-36.5-39.7-64-78.4-64s-71 27.5-78.4 64L16 400c-8.8 0-16 7.2-16 16zm112 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM304 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48-80c-38.7 0-71 27.5-78.4 64L16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l257.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64l65.6 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-65.6 0c-7.4-36.5-39.7-64-78.4-64zM192 144a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm78.4-64C263 43.5 230.7 16 192 16s-71 27.5-78.4 64L16 80C7.2 80 0 87.2 0 96s7.2 16 16 16l97.6 0c7.4 36.5 39.7 64 78.4 64s71-27.5 78.4-64L496 112c8.8 0 16-7.2 16-16s-7.2-16-16-16L270.4 80z"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div class="block__header__options" v-if="showContractedProducts">
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox"
                                           v-model="showContractedProductsValue" @change="fetchProducts(false)"
                                           name="contractedProducts" id="product-list-toggle__1">
                                    <label class="form-check-label" for="product-list-toggle__1">
                                        {{ translations.contractedProducts }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block__header__wrapper__right">
                        <WebshopHeaderSort :products="products"
                                           :loading="loading"
                                           :total-products="totalProducts"
                                           :initial-sort="selectedSortOption"
                                           @sort-change="sortChange">
                        </WebshopHeaderSort>
                    </div>
                </div>

                <div class="block__header__filter-active">
                    <ul v-if="totalActiveFilters > 0">
                        <template v-for="(ids, filterKey) in selectedFilters" :key="filterKey">
                            <li v-for="id in ids">
                                <a class="active-filter text-uppercase" href="#"
                                   @click.prevent="removeFilter(filterKey, id)"
                                   :data-category-id="id"
                                   :data-category-group-handle="getFilterGroupHandle(filterKey)"
                                   data-filter-type="input">
                                    {{ getFilterTitle(filterKey, id) }}
                                </a>
                            </li>
                        </template>
                        <template v-for="(minMaxObj, filterKey) in selectedSizes" :key="filterKey">
                            <li>
                                <a class="active-filter text-uppercase" href="#"
                                   @click.prevent="removeFilter(filterKey, null)"
                                   data-category-group-handle="sizes"
                                   data-category-id="0"
                                   :data-filter-name="filterKey"
                                   :data-visible-name="translations[filterKey] || ''"
                                   data-filter-type="slider">
                                    {{ translations[filterKey] || '' }} ({{minMaxObj.min}} - {{ minMaxObj.max }})
                                </a>
                            </li>
                        </template>
                        <li>
                            <a href="#" class="reset-filter text-uppercase"
                               @click.prevent="emptyFilters()">
                                {{ translations.emptyFilter }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="block__body">
        <div class="container">
            <div class="row list list__product">
                <template v-if="initialLoading">
                    <article class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 listelement" v-for="i in 4">
                        <ProductListItem :ids="[0, 0]" :product-data="null"></ProductListItem>
                    </article>
                </template>
                <template v-else-if="showNoProductsTemplate">
                    <div class="w-100 pt-4">
                        <div class="block__header text-center">
                            <div class="container">
                                <div class="block__header__title">
                                    <h2>Ingen produkter å vise</h2>
                                </div>
                            </div>
                        </div>
                        <div class="block__body text-center">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8">
                                        <div class="text" v-html="noProductsText"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="block__footer text-center">
                            <div class="container d-flex justify-content-center gap-2">
                                <a href="/produkter" class="bttn color--color4"><span>Vis alle produkter</span></a>
                                <a href="/kontakt" class="bttn bttn--outline color--color1 bttn--contact"><span>Kontakt rådgiver</span></a>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <article v-for="product in products" :key="product.variantId"
                             class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 listelement"
                             data-can-be-removed="0" :data-purchasable-id="product.variantId">
                        <ProductListItem :productData="product"></ProductListItem>
                    </article>
                </template>
            </div>
        </div>
    </div>
    <div class="block__footer">
        <WebshopFooter :products="products"
                       :total-products="totalProducts"
                       @load-more="loadMore">
        </WebshopFooter>
    </div>
    <!-- TODO: show "no products found" (from footer.twig) -->
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue';
import WebshopHeader from "./WebshopHeader.vue";
import WebshopFooter from "./WebshopFooter.vue";
import WebshopHeaderSort from "./WebshopHeaderSort.vue";
import ProductListItem from "./ProductListItem.vue";
import mitt from 'mitt';
import { getSpinner, getSyncSpinner } from "../helpers";
import { webshopFiltersMapping } from "../vue-apps";

defineComponent({
    name: "Webshop"
});

const props = defineProps(['hideWebshopHeader', 'title', 'categoryId', 'categoryDescription', 'csrfToken', 'showContractedProducts', 'noProductsText']);
const translations = window.lindbakVueTranslations;

const initialLoading = ref(true);
const loading = ref(true);
const products = ref([]);
const totalProducts = ref(0);
const showNoProductsTemplate = computed(() => {
    return totalProducts.value === 0 && ! loading.value;
});
const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;

const selectedSortOption = ref('standard:asc');
const selectedFilters = ref({});
const selectedSizes = ref({});
const currentPage = ref(1);
const totalActiveFilters = computed(() => {
    return Object.keys(selectedFilters.value).reduce((total, filterKey) => {
        return total + selectedFilters.value[filterKey].length || 0;
    }, 0) + Object.keys(selectedSizes.value).length;
});
let allFilters = null;
const categoryIdNameMap = new Map();
const filtersMapping = webshopFiltersMapping;
const showContractedProductsValue = ref(false);
const searchString = ref('');

onMounted(async () => {
    const {urlFilterParams, urlSizeParams, contractedProductsFilter, search} = parseUrlFilterParams();
    showContractedProductsValue.value = contractedProductsFilter;
    searchString.value = search;
    if (Object.keys(urlFilterParams).length + Object.keys(urlSizeParams).length === 0) {
        await fetchProducts();
    } else {
        selectedSizes.value = {...urlSizeParams};
        selectedFilters.value = {...urlFilterParams};
        emitter.emit('selectedFiltersUpdated', selectedFilters.value);
        emitter.emit('selectedSizesUpdated', selectedSizes.value);
        await fetchProducts();
        emitter.emit('selectedFiltersUpdated', selectedFilters.value);
        emitter.emit('selectedSizesUpdated', selectedSizes.value);
    }
    getSpinner();
});

const parseUrlFilterParams = () => {
    const filterKeys = [... Object.keys(filtersMapping)];
    const sizeKeys = ['width', 'height', 'length'];
    const urlParams = (new URL(location.href)).searchParams;
    const urlFilterParams = {};
    for (const fKey of filterKeys) {
        if (urlParams.has(fKey)) {
            urlFilterParams[fKey] = urlParams.get(fKey).split(',').map(id => !isNaN(parseInt(id)) ? parseInt(id) : id);
        }
    }
    const urlSizeParams = {};
    for (const sKey of sizeKeys) {
        if (urlParams.has(sKey)) {
            try {
                urlSizeParams[sKey] = JSON.parse(urlParams.get(sKey));
            } catch (e) {
                // Silent fail
            }
        }
    }
    const contractedProductsFilter = urlParams.has('contractedProducts') && urlParams.get('contractedProducts') === '1';
    const search = urlParams.has('q') ? urlParams.get('q') : '';
    return { urlFilterParams, urlSizeParams, contractedProductsFilter, search };
};

const processCategories = categories => {
    for (const category of categories) {
        categoryIdNameMap.set(category.id, category.title);
        if (category.children) {
            processCategories(category.children);
        }
    }
};

const getFilterTitle = (filterKey, id) => {
    const fKey = filtersMapping[filterKey] || filterKey;
    if (! allFilters || allFilters[fKey] === undefined) {
        return '';
    }
    if (fKey === 'productCategory') {
        return categoryIdNameMap.get(id) || '';
    } else {
        return allFilters[fKey].find(f => f.id === id)?.title || '';
    }
};
const getFilterGroupHandle = (filterKey) => {
    return filtersMapping[filterKey] || filterKey;
};

const assembleSearchParams = () => {
    let searchParams = {};
    if (selectedSortOption.value !== 'standard:asc') {
     searchParams.sort = selectedSortOption.value;   
    }
    if (props.categoryId) {
        searchParams.productCategoryId = props.categoryId;
    }
    if (currentPage.value > 1) {
        searchParams.page = currentPage.value;
    }
    if (Object.keys(selectedFilters.value).length) {
        for (const filterKey of Object.keys(selectedFilters.value)) {
            if (selectedFilters.value[filterKey] && selectedFilters.value[filterKey].length > 0) {
                searchParams[filterKey] = selectedFilters.value[filterKey];
            }
        }
    }
    const sizeKeys = Object.keys(selectedSizes.value);
    if (sizeKeys.length) {
        for (const sizeKey of sizeKeys) {
            searchParams = { ...searchParams, [sizeKey]: JSON.stringify(selectedSizes.value[sizeKey]) };
        }
    }
    if (props.showContractedProducts && showContractedProductsValue.value) {
        searchParams = { ...searchParams, contractedProducts: 1 };
    }
    if (searchString.value) {
        searchParams = { ...searchParams, q: searchString.value };
    }
    return searchParams;
};

let lastRequestSearchParams = '';
let currentAbortController = null;
const fetchProducts = async (append = false) => {
    if (! append) {
        currentPage.value = 1;
    }
    const searchParams = assembleSearchParams();
    const searchParamsString = JSON.stringify(searchParams);
    if (searchParamsString === lastRequestSearchParams) {
        return;
    }
    updateUrl(searchParams);
    if (currentAbortController) {
        currentAbortController.abort();
    }
    currentAbortController = new AbortController();
    try {
        loading.value = true;
        if (! initialLoading.value) {
            getSyncSpinner()?.show();
        }
        lastRequestSearchParams = searchParamsString;
        const response = await fetch('/vue/get-products', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Csrf-Token': props.csrfToken,
            },
            body: searchParamsString,
            signal: currentAbortController.signal,
        });
        const data = await response.json();
        if (data.success) {
            if (append) {
                products.value.push( ... data.productData );
            } else {
                products.value = data.productData;
            }
            totalProducts.value = data.totalProducts;
            allFilters = allFilters || data.filters; // save only on first fetch
            if (allFilters.productCategory) {
                processCategories(allFilters.productCategory);
            }
            emitter.emit('filtersUpdated', data.filters);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Previous fetch request aborted');
        } else {
            console.error('Error fetching product data:', error);
        }
    } finally {
        initialLoading.value = false;
        loading.value = false;
        getSyncSpinner()?.hide();
    }
};
const updateUrl = searchParams => {
    const modifiedUrl = new URL(location.href);
    if (Object.keys(searchParams).length === 1 && modifiedUrl.searchParams.size === 1 && searchParams.productCategoryId > 0) {
        return;
    }
    [...modifiedUrl.searchParams.keys()].forEach(key => modifiedUrl.searchParams.delete(key));
    for (const key of Object.keys(searchParams)) {
        const newValue = Array.isArray(searchParams[key]) ? searchParams[key].join(',') : searchParams[key];
        if (newValue) {
            modifiedUrl.searchParams.set(key, newValue);
        }
    }
    history.pushState({}, '', modifiedUrl.toString());
};

const sortChange = sortOptionValue => {
    selectedSortOption.value = sortOptionValue;
    fetchProducts();
};

const loadMore = () => {
    currentPage.value ++;
    fetchProducts(true);
};

const filterChanged = (activeFilters) => {
    selectedFilters.value = activeFilters;
    fetchProducts();
};
emitter.on('activeFilterChange', filterChanged);

const sizesChanged = (activeSizes) => {
    selectedSizes.value = {};
    if (activeSizes.height) {
        selectedSizes.value.height = activeSizes.height;
    }
    if (activeSizes.width) {
        selectedSizes.value.width = activeSizes.width;
    }
    if (activeSizes.length) {
        selectedSizes.value.length = activeSizes.length;
    }
    fetchProducts();
};
emitter.on('activeSizesChange', sizesChanged);

const emptyFilters = () => {
    selectedFilters.value = {};
    emitter.emit('selectedFiltersUpdated', selectedFilters.value);
    selectedSizes.value = {};
    emitter.emit('selectedSizesUpdated', selectedSizes.value);
    fetchProducts();
};
const removeFilter = (filterKey, id) => {
    if (selectedFilters.value[filterKey]) {
        const idx = selectedFilters.value[filterKey].indexOf(id);
        if (idx === -1) {
            return;
        }
        selectedFilters.value[filterKey].splice(idx, 1);
        emitter.emit('selectedFiltersUpdated', selectedFilters.value);
        fetchProducts();
    } else if (selectedSizes.value[filterKey]) {
        delete selectedSizes.value[filterKey];
        emitter.emit('selectedSizesUpdated', selectedSizes.value);
        fetchProducts();
    }
};
</script>