<template>
    <li :class="{ 'has-children' : category.children && category.children.length > 0, open: openCategories.has(category.id) }">
        <div class="form-check">
            <!-- TODO: selectedCategories should be parsed from URL -->
            <input class="form-check-input" type="checkbox"
                   :data-filter-name="filterKey"
                   :data-filter-title="category.title"
                   :data-category-group-handle="category.categoryGroupHandle"
                   :id="'product-filter__' + filterRandomId"
                   :value="category.id"
                   :checked="isSelected"
                   @change="selectionChange(category.id, $event)">
            <label class="form-check-label" :for="'product-filter__' + filterRandomId">
                <span @click="labelClick(category, $event)">{{ category.title }} ({{ category.numOfRelations }})</span>
            </label>
        </div>
        <ul v-if="category.children && category.children.length > 0">
            <FilterCategoryItem v-for="cat in category.children"
                                :category="cat"
                                :filter-key="filterKey"
                                @category-selection-change="passChangeEvent">
            </FilterCategoryItem>
        </ul>
    </li>
</template>

<script setup>

import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { randomString } from "../helpers";
import mitt from "mitt";

defineComponent({
    name: "FilterCategoryItem"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const props = defineProps(['category', 'filterKey']);
const emit = defineEmits(['categorySelectionChange']);
const filterRandomId = randomString();
const isSelected = ref(false);
const openCategories = ref(new Set());

const labelClick = (category, $event) => {
    if (category.children && category.children.length > 0) {
        $event.preventDefault();
        if (openCategories.value.has(category.id)) {
            openCategories.value.delete(category.id);
        } else {
            openCategories.value.add(category.id);
        }
    }
};

const selectionChange = (id, $event) => {
    isSelected.value = $event.target.checked;
    emitChange();
};

const emitChange = () => {
    emit('categorySelectionChange', { id: props.category.id, isSelected: isSelected.value });
};

const passChangeEvent = data => {
    emit('categorySelectionChange', data);
};

onMounted(() => {
    emitter.on('selectedFiltersUpdated', selectedFiltersUpdated);
});
onUnmounted(() => {
    emitter.off('selectedFiltersUpdated', selectedFiltersUpdated);
});

const selectedFiltersUpdated = selectedFilters => {
    if (selectedFilters.productCategoryIds && selectedFilters.productCategoryIds.length) {
        isSelected.value = selectedFilters.productCategoryIds.includes(props.category.id);
    } else {
        isSelected.value = false;
    }
};

</script>