import {addDynamicEventListener, createPostRequest, getClosest, getSpinner} from "./helpers";
export function initMainSearch() {
    document.addEventListener('DOMContentLoaded', () => {
        const header = document.querySelector('.site__header');
        if( header instanceof HTMLElement ) {
            init();
        }
    });
}

let searchTimeout;
let searchInput;
let searchTerm;
let searchHistoryContainer;
let popularSearchesContainer;
let serverResultContainer;
let abortControllers = [];
let lastSearchTerm;
const maxEntriesInSearchHistory = 5;
const maxEntriesToShowFromSearchHistory = 2;

async function init() {
    searchHistoryContainer = document.querySelector('.search__dropdown__searchHistory');
    popularSearchesContainer = document.querySelector('.search__dropdown__popularSearches');
    searchInput = document.querySelector('input#header-search');
    serverResultContainer = document.querySelector('.results-from-server');
    const popularSearchTerms = document.querySelectorAll('.popular-search-term');
    const searchButton = document.querySelector('form.search .search__button > button');
    const searchForm = document.querySelector('form.search');
    
    initSearchHistory();
    
    if( searchButton instanceof HTMLElement ) {
        searchButton.addEventListener('click', (e) => {
            window.location.assign(`/sok?q=${searchTerm}`);
        })
    } 
    
    if( searchInput instanceof HTMLElement ) {
        const url = new URL(window.location.href);
        const queryString = url.searchParams.get('q');
        if( queryString && queryString.length ) {
            searchTerm = queryString;
            searchInput.value = queryString;
            toggleMiscContainerVisibility();
            startSearch();
        }
        
        searchInput.addEventListener('keyup', searchHandler);
        searchInput.addEventListener('search', (e) => {
            e.preventDefault();
            e.stopPropagation();
            searchTerm = e.target.value;
            if( searchTerm.length === 0 ) {
                toggleMiscContainerVisibility(false);
                updateResults();
            }
        });
    }
    
    if( searchForm instanceof HTMLElement ) {
        searchForm.addEventListener('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if( searchInput instanceof HTMLElement ) {
                searchTerm = searchInput.value;
                if( searchTerm.length ) {
                    document.documentElement.classList.remove('header-search--open', 'noscroll', 'overlay');
                    window.location.assign(`/sok?q=${searchTerm}`);
                }
            }
        });
    }
    
    if( searchHistoryContainer instanceof HTMLElement ) {
        addDynamicEventListener(searchHistoryContainer, 'click', '.empty > a', (e) => clearSearchHistory(e) );
    }
    
    if( serverResultContainer instanceof HTMLElement ) {
        addDynamicEventListener(document, 'click', '.results-from-server li.search__dropdown__prod__item a', (e) => addToSearchHistory(e) );
    }
    
    if( popularSearchTerms.length ) {
        [...popularSearchTerms].map(el => {
            el.addEventListener('click', popularSearchTermHandler);
        })
    }
}

const toggleMiscContainerVisibility = (hide = true) => {
    if( searchHistoryContainer instanceof HTMLElement && serverResultContainer instanceof HTMLElement ) {
        if( hide ) {
            searchHistoryContainer.classList.add('d-none');
            if( popularSearchesContainer ) {
                popularSearchesContainer.classList.add('d-none');
            }
            serverResultContainer.classList.remove('d-none');
        } else {
            searchHistoryContainer.classList.remove('d-none');
            if( popularSearchesContainer ) {
                popularSearchesContainer.classList.remove('d-none');
            }
            serverResultContainer.classList.add('d-none');
        }   
    }
}

// clear results when search term is empty or length is less than 3
const clearResults = (showSpinner = false) => {
    if( showSpinner ) {
        serverResultContainer.innerHTML = `
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    `;   
    } else {
        serverResultContainer.innerHTML = '';
    }
}

const searchHandler = (event) => {
    searchTerm = event.target.value.toString();
    if( searchTerm !== lastSearchTerm ) {
        toggleMiscContainerVisibility(searchTerm.length > 0);
        clearResults();
        if( searchTerm.length >= 3 ) {
            startSearch(true);
        }   
    }
}

const startSearch = async (timeout = false) => {
    if( searchTerm !== lastSearchTerm ) {
        clearTimeout(searchTimeout);
        if (timeout === true) {
            searchTimeout = setTimeout(startSearch, 300);
            return;
        }

        lastSearchTerm = searchTerm;
        const serverResponse = await search(searchTerm);
        if( serverResponse.response.success && serverResponse.response.hasOwnProperty('html')  ) {
            updateResults(serverResponse.response.html)
        }
    }
}

const search = async (term) => {
    if( abortControllers.length ) {
        abortControllers.map(controller => controller.abort());
    }
    const abortController = new AbortController();
    abortControllers = [abortController];
    clearResults(true);
    return await createPostRequest('/sok/quick', {q: term}, 'application/json', abortController.signal);
}

const updateResults = (htmlText) => {
    const tempDOM = (new DOMParser()).parseFromString(htmlText, "text/html");
    const newContainer = tempDOM.querySelector('.results-from-server');
    if( serverResultContainer instanceof HTMLElement && newContainer instanceof HTMLElement ) {
        serverResultContainer.replaceWith(newContainer);
        serverResultContainer = newContainer;
    }
}

const addToSearchHistory = (e) => {
    const listItem = getClosest(e.target, 'li');
    if( listItem instanceof HTMLElement) {
        const id = listItem.dataset.productId;
        const object = {
            id: id,
            htmlString: listItem.innerHTML
        }
        const redirectUrl = listItem.firstElementChild.getAttribute('href');
        let exists = false;
        let currentItems = localStorage.getItem('searchHistory');
        if( currentItems ) {
            currentItems = JSON.parse(currentItems);
            currentItems.map(savedItem => {
                if( !exists ) {
                    exists = savedItem.id === object.id;
                }
            });
        } else {
            currentItems = [];
        }
    
        if( !exists ) {
            currentItems.unshift(object);
            currentItems = currentItems.slice(0, maxEntriesInSearchHistory);
            localStorage.setItem('searchHistory', JSON.stringify(currentItems));
        }

        if( redirectUrl ) {
            e.preventDefault();
            e.stopPropagation();
            window.location.assign(redirectUrl);
        }
    } else {
        console.error(`Couldn't find mandatory HTML list element. Maybe markup has changed?`);
    }
}

const getSearchHistory = (numberOfEntries = maxEntriesInSearchHistory) => {
    let currentItems = localStorage.getItem('searchHistory');
    if( currentItems ) {
        currentItems = JSON.parse(currentItems);
        return currentItems.slice(0, numberOfEntries);
    }
    return [];
}

const initSearchHistory = () => {
    const products = getSearchHistory(maxEntriesToShowFromSearchHistory);
    const list = document.querySelector('.search__dropdown__prod > ul');
    if( products.length ) {
        searchHistoryContainer.classList.remove('d-none');
        products.map(object => {
            const element = document.createElement('li');
            element.innerHTML = object.htmlString;
            list.appendChild(element);
        });
    } else {
        searchHistoryContainer.classList.add('d-none');
    }
}

const clearSearchHistory = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.removeItem('searchHistory');
    initSearchHistory();
}


const popularSearchTermHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const anchor = getClosest(e.target, 'a');
    if( anchor instanceof HTMLElement && searchInput instanceof HTMLElement ) {
        searchInput.value = anchor.textContent;
        searchTerm = anchor.textContent;
        toggleMiscContainerVisibility();
        startSearch();
    }
}