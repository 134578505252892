import * as bootstrap from 'bootstrap';
import {createPostRequest, showToast, updateCartIndicator} from "./helpers";

export function initMyPageFunctions() {
    document.addEventListener('DOMContentLoaded', () => {
        initTabs();
        initCardSwitch();
        initOrders();
    });
}

function initTabs() {
    const myPageTabs = document.querySelectorAll('ul.nav-tabs li button[data-push-url]');
    let popStateHappening = false;
    for (const myPageTab of myPageTabs) {
        myPageTab.addEventListener('show.bs.tab', event => {
            if (popStateHappening) {
                return;
            }
            const url = event.target.dataset.pushUrl;
            history.pushState({page: url}, '', url);
        })
    }

    window.addEventListener('popstate', function(event) {
        const currentUrl = window.location.href;
        for (const myPageTab of myPageTabs) {
            if (myPageTab.dataset.pushUrl === currentUrl) {
                let tab = bootstrap.Tab.getInstance(myPageTab);
                if (!tab) {
                    tab = new bootstrap.Tab(myPageTab);
                }
                popStateHappening = true;
                tab.show();
                popStateHappening = false;
                break;
            }
        }
    });
}

function initCardSwitch() {
    const customerCardDropdown = document.querySelector('.customer-card-select');
    if (customerCardDropdown) {
        const cardLinks = customerCardDropdown.querySelectorAll('a[data-card-id]');
        for (const cardLink of cardLinks) {
            cardLink.addEventListener('click', async e => {
                e.preventDefault();
                const cardId = e.target.closest('a').dataset.cardId;
                try {
                    const reply = await createPostRequest('/my-page/set-customer-card', { cardId });
                } catch (e) {
                }
                window.location.reload();
            });
        }
    }
}

function initOrders() {
    const orderAgainButtons = document.querySelectorAll('.bttn--orderAgain');
    if( orderAgainButtons.length ) {
        [...orderAgainButtons].map(button => {
            if( button instanceof HTMLAnchorElement) {
                button.addEventListener('click', async(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const orderId = button.hasAttribute('data-order-id') ? button.dataset.orderId : false;
                    if( orderId ) {
                        await orderAgain(button, orderId);
                    } else {
                        await showToast('There was an error, please contact support about the issue.', 'Aww snap', true, true, 2500);
                    }
                });
            }
        });
    }
    
    const orderHistoryFilterButtons = document.querySelectorAll('.block__orderHistory .block__header__cta a[data-order-status]');
    const orderAccordions = document.querySelectorAll('.accordion-item.webshopOrder');

    if( orderHistoryFilterButtons.length && orderAccordions.length ) {
        [...orderHistoryFilterButtons].map(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                [...button.closest('ul').querySelectorAll('li a.active')].map(element => element.classList.remove('active'));
                button.classList.add('active');
                //const selectedStatus = button.dataset.orderStatus;
                const availableStatuses = JSON.parse(button.dataset.orderStatus);
                filterOrders(availableStatuses);
            });
        });
    }
    
    const filterOrders = (statuses) => {
        console.log(statuses);
        [...orderAccordions].map(order => {
            const isVisible = statuses.includes(order.dataset.orderStatus);
            console.log({statuses, order, o: order.dataset.orderStatus, isVisible});
            //const isVisible = status === '*' || status === order.dataset.orderStatus;
            if( isVisible ) {
                order.classList.remove('d-none');
            } else {
                order.classList.add('d-none');
            }
        });
    };
}

async function orderAgain(button, orderId) {
    const reorderResponse = await createPostRequest('/webshop/reorder-cart', {orderId});
    if( reorderResponse.response.success ) {
        await updateCartIndicator();
        if( reorderResponse.response.hasOwnProperty('numOfAddedItems') ) {
            await showToast(`${reorderResponse.response.numOfAddedItems} produkter er lagt til din handlekurv.`, 'GRATULERER', true, true, 2000);
        }
    } else {
        await showToast('Gjentagelse av bestilling feiler pga. at minst et av produktene ikke lenger er tilgjengelig for din bruker via netthandel. Vennligst kontakt oss via chat slik at vi kan hjelpe deg videre.', 'BEKLAGER', true, true, 2500);
    }
}