import {createModal, createPostRequest, showToast, validateBSForm} from "./helpers";

let privateRegistrationForm;
let companyRegistrationForm;
export function initRegistration() {
    document.addEventListener('DOMContentLoaded', () => {
        privateRegistrationForm = document.querySelector('form.registration-form.private');
        companyRegistrationForm = document.querySelector('form.registration-form.company');
        if( privateRegistrationForm instanceof HTMLFormElement || companyRegistrationForm instanceof HTMLFormElement ) {
            init();
        }
    });
}

function init() {
    if( privateRegistrationForm ) {
        privateRegistrationForm.addEventListener('submit', registrationHandler);
    } else if( companyRegistrationForm ) {
        companyRegistrationForm.addEventListener('submit', registrationHandler);
    }
}

const registrationHandler = async(event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.target;

    if( !validateBSForm(form) ) {
        await showToast('Vennligst sjekk og korriger inndata!', 'Error', true);
        return;
    }

    const firstNameInput = form.querySelector('input[name="firstName"]');
    const lastNameInput = form.querySelector('input[name="lastName"]');
    const emailInput = form.querySelector('input[name="email"]');
    
    if( firstNameInput instanceof HTMLInputElement && lastNameInput instanceof HTMLInputElement && emailInput instanceof HTMLInputElement ) {
        if( form.classList.contains('private') ) {
            return await privateRegistrationHandler(firstNameInput.value, lastNameInput.value, emailInput.value);
        } else if( form.classList.contains('company') ) {
            const orgNumberInput = form.querySelector('input[name="orgNumber"]');
            if( orgNumberInput instanceof HTMLInputElement ) {
                return await companyRegistrationHandler(firstNameInput.value, lastNameInput.value, emailInput.value, orgNumberInput.value);
            } else {
                console.error("Couldn't find organization number input");
            }
        } else {
            console.error("Couldn't determine if registration form is for private users or company. Missing form classes?");
        }
    } else {
        console.error("Couldn't find mandatory input elements");
    }
}

const privateRegistrationHandler = async(firstName, lastName, email) => {
    const registrationResponse = await createPostRequest('/registration/create-private-customer', { firstName, lastName, email });

    if( registrationResponse.response.success ) {
        const modal = await createModal('Aktiverings e-post er sendt', 'Vi har sendt deg en e-post med en aktiveringslenke som du må trykke på.', 'Ok');
        modal.show();
    } else {
        if( registrationResponse.response.hasOwnProperty('errors') ) {
            registrationResponse.response.errors.map(error => {
                showToast(error);
            })
        }
    }
}

const companyRegistrationHandler = async(firstName, lastName, email, organizationNumber) => {
    // company lookup by organization number (bronnoysund register)
    const companyLookupResponse = await createPostRequest('/registration/company-lookup', { organizationNumber });
    if( !companyLookupResponse.response.success ) {
        // if there was an error with the lookup, we show the error and stop execution
        if( companyLookupResponse.response.hasOwnProperty('error') ) {
            if( companyLookupResponse.response.hasOwnProperty('highlightOrgNumberInput') && companyLookupResponse.response.highlightOrgNumberInput ) {
                const orgNumberInput = document.querySelector('input[name="orgNumber"]');
                showCustomInvalidFeedback(orgNumberInput, companyLookupResponse.response.error);   
            }
            await showToast(companyLookupResponse.response.error, 'Error', true, true, 5000);
            return;
        }
    }
    
    if( !companyLookupResponse.response.hasOwnProperty('customerCardId') ) {
        await showToast("Kunne ikke opprette brukeren. Ta kontakt med Lindbak kundeservice.", 'Error', true, true, 5000);
        console.error("Customer card id was missing from response.");
        return;
    }

    // if company was validated and created, we create the user and link it to the company
    const registrationResponse = await createPostRequest('/registration/create-b2c-customer', { firstName, lastName, email, customerCardId: companyLookupResponse.response.customerCardId });
    if( registrationResponse.response.success ) {
        const modal = await createModal('Aktiverings e-post er sendt', 'Vi har sendt deg en e-post med en aktiveringslenke som du må trykke på.', 'Ok');
        modal.show();
    } else {
        if( registrationResponse.response.hasOwnProperty('errors') ) {
            registrationResponse.response.errors.map(error => {
                showToast(error);
            })
        }
    }
}

const showCustomInvalidFeedback = (input, message) => {
    const feedbackContainer = input.parentNode.parentNode.querySelector('.invalid-feedback');
    if( input instanceof HTMLInputElement && feedbackContainer instanceof HTMLElement ) {
        input.classList.remove('is-valid');
        input.classList.add('is-invalid');
        feedbackContainer.textContent = message;
        feedbackContainer.style.display = 'block';
    }
}