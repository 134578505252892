<template>
    <div class="row">
        <div class="col-md-8 col-lg-8 col-xl-8 col-xxl-8">
            <div class="block__header__header__title">
                <h1 class="h2">{{ title }}</h1>
            </div>
            <div class="block__header__header__intro" v-if="categoryDescription">
                <div v-html="categoryDescription" 
                     class="truncate-text"
                     :class="{ expanded: categoryDescriptionExpanded }"></div>
                <button class="link" @click.prevent="categoryDescriptionExpanded = ! categoryDescriptionExpanded">
                    {{ categoryDescriptionExpanded ? translations.showLess : translations.readMore }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineComponent, ref } from 'vue';

defineComponent({
    name: "WebshopHeader"
});

const props = defineProps(['title', 'categoryDescription']);
const translations = window.lindbakVueTranslations;
const categoryDescriptionExpanded = ref(false);

</script>