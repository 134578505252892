export function initJqueryExtensions() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    $.fn.alterClass = function (removals, additions) {
        const self = this;

        if (removals.indexOf('*') === -1) {
            // Use native jQuery methods if there is no wildcard matching
            self.removeClass(removals);
            return !additions ? self : self.addClass(additions);
        }

        const patt = new RegExp('\\s' +
            removals.
            replace(/\*/g, '[A-Za-z0-9-_]+').
            split(' ').
            join('\\s|\\s') +
            '\\s', 'g');

        self.each(function (i, it) {
            let cn = ' ' + it.className + ' ';
            while (patt.test(cn)) {
                cn = cn.replace(patt, ' ');
            }
            it.className = $.trim(cn);
        });

        return !additions ? self : self.addClass(additions);
    };

    $.fn.rCounter = function (options) {
        const settings = $.extend({
            duration: 20,
            easing: 'swing',
        }, options);
        return this.each(function () {
            const $this = $(this);

            const startCounter = function () {
                const numbers = [];
                let number = $this.text();
                const isComma = /[,\-]/.test(number);
                const isFloat = /[,\-]/.test(number);
                number = number.replace(/,/g, '');
                const divisions = settings.duration;
                const decimalPlaces = isFloat ? (number.split('.')[1] || []).length : 0;

                // make number string to array for displaying counterup
                for (let rcn = divisions; rcn >= 1; rcn--) {

                    let newNum = parseInt(number / divisions * rcn);
                    if (isFloat) {
                        newNum = parseFloat(number / divisions * rcn).toFixed(decimalPlaces);
                    }
                    if (isComma) {
                        while (/(\d+)(\d{3})/.test(newNum.toString())) {
                            newNum = newNum.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
                        }
                    }

                    numbers.unshift(newNum);
                }
                const counterUpDisplay = function () {
                    $this.text(numbers.shift());
                    setTimeout(counterUpDisplay, settings.duration);
                };
                setTimeout(counterUpDisplay, settings.duration);
            } // end function

            //bind with waypoints
            new Waypoint({
                element: $this[0],
                handler: function() {
                    startCounter();
                    this.destroy();
                },
                offset: '100%',
            })
            //$this.waypoint(startCounter, { offset: '100%', triggerOnce: true });
        });
    }
}
