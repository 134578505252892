<template>
    <div v-for="(ids, index) in productVariantIds" :key="index" class="swiper-slide">
        <article class="listelement" :class="getClasses(ids)" :data-purchasable-id="getPurchasableId(ids)">
            <ProductListItem :productData="products[ids.join('-')]" :ids="ids"></ProductListItem>
        </article>
    </div>
</template>

<script setup>
import { defineComponent, onMounted, ref } from 'vue';
import ProductListItem from "./ProductListItem.vue";

defineComponent({
    name: "ProductSwiperSlides"
});
const props = defineProps(['productVariantIds']);

const products = ref({});

onMounted(() => {
    fetchProductData();
});

const fetchProductData = async () => {
    try {
        const response = await fetch('/vue/get-product-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productVariantIds: props.productVariantIds })
        });
        const data = await response.json();
        if (data.success) {
            props.productVariantIds.forEach(ids => {
                const key = ids.join('-');
                products.value[key] = data.productData.find(product => product.productId === ids[0] && (null === ids[1] || product.variantId === ids[1]));
            });
        }
    } catch (error) {
        console.error('Error fetching product data:', error);
    }
};

const getClasses = (ids) => {
    const product = products.value[ids.join('-')];
    return product ? [ product.stockClass, product.priceClass ] : null;
}

const getPurchasableId = (ids) => {
    const product = products.value[ids.join('-')];
    return product ? product.purchasableId : null;
};
</script>
