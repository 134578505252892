<template>
    <div class="accordion-item">
        <h2 class="accordion-header" id="allFilters_heading_categories">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#allFilters_collapse_categories"
                    aria-expanded="false" aria-controls="allFilters_collapse_categories">
                {{ translations.productCategory }}
            </button>
        </h2>
        <div id="allFilters_collapse_categories" class="accordion-collapse collapse"
             aria-labelledby="allFilters_heading_categories" data-bs-parent="#allFilters"
             data-category-group-handle="productCategory">
            <div class="accordion-body">
                <div class="filter--category">
                    <div class="form-group">
                        <fieldset>
                            <ul>
                                <template v-for="cat in categories">
                                    <FilterCategoryItem v-if="cat.showInCategoryTree" 
                                                        :category="cat"
                                                        :filter-key="filterKey"
                                                        @category-selection-change="categorySelectionChange">
                                    </FilterCategoryItem>
                                </template>
                            </ul>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { defineComponent, onMounted, onUnmounted } from "vue";
import FilterCategoryItem from "./FilterCategoryItem.vue";
import mitt from "mitt";

defineComponent({
    name: "FilterCategories"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const props = defineProps(['categories', 'filterKey']);
const emit = defineEmits(['filterChange']);
const translations = window.lindbakVueTranslations;
const selectedCategories = new Set(); // no need for ref()
// TODO URL category parameters should be parsed and passed down

const emitFilterChange = () => {
    emit('filterChange', {filterName: props.filterKey, selectedOptions: [...selectedCategories.values()]});
};

const categorySelectionChange = ({ id, isSelected }) => {
    if (isSelected) {
        selectedCategories.add(id);
    } else {
        selectedCategories.delete(id);
    }
    emitFilterChange();
};

onMounted(() => {
    emitter.on('selectedFiltersUpdated', selectedFiltersUpdated);
});
onUnmounted(() => {
    emitter.off('selectedFiltersUpdated', selectedFiltersUpdated);
});

const selectedFiltersUpdated = selectedFilters => {
    selectedCategories.clear();
    if (selectedFilters.productCategoryIds && selectedFilters.productCategoryIds.length) {
        for (const catId of selectedFilters.productCategoryIds) {
            selectedCategories.add(catId);
        }
    }
    emitFilterChange();
};

</script>