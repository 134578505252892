import {
    queryCompaniesBySearchTerm,
    queryCompanyAddressByOrgNumber,
    validateCompany
} from './two';
import {
    createPopover, createPostRequest, getSpinner,
    postFormData,
    setPopoverContent,
    showToast
} from "./helpers";
import {Popover} from "bootstrap";
import {GTMAddShippingInfo} from "./analytics";

export function initCheckoutStep1() {
    document.addEventListener('DOMContentLoaded', () => {
        const container = document.querySelector('.checkoutStep--1');
        if( container instanceof HTMLElement ) {
            init();
        }
    });
}

let companyNameInput;
let companyNameDataList;
let organizationNumberInput;
let abortControllers = [];
const checkoutContainer = document.querySelector('.block__shop__checkout');
const paymentInputInvoice = document.getElementById('form__checkout__payment__invoice');
const paymentLabelInvoice = document.querySelector('label[for="form__checkout__payment__invoice"]');
let paymentLabelInvoicePopover;
const paymentInputBankCard = document.getElementById('form__checkout__payment__card');
const paymentLabelBankCard = document.querySelector('label[for="form__checkout__payment__card"]');
const submitButton = document.querySelector('.bttn.continue-checkout');
const paymentForms = document.querySelectorAll('.block__shop__checkout form');
let spinner;

async function init() {
    companyNameInput = document.getElementById('form__business__input__billingCompanyName');
    companyNameDataList = document.getElementById('company-names');
    organizationNumberInput = document.getElementById('form__business__input__billingCompanyOrganizationNumber');
    
    initPrivatBedriftTogglers();
    initFormValidityHandler();
    spinner = await getSpinner();

    if( paymentLabelInvoice instanceof HTMLLabelElement ) {
        paymentLabelInvoicePopover = createPopover(paymentLabelInvoice);
        paymentLabelInvoice.addEventListener('click', (e) => {
            if( paymentInputInvoice.disabled ) {
                paymentLabelInvoicePopover.show();
            }
        });
    }
    
    if( checkoutContainer instanceof HTMLElement ) {
        const validateCompanyOnPageLoad = checkoutContainer.hasAttribute('data-validate-company') && checkoutContainer.dataset.validateCompany;
        const companyOrgNumber = checkoutContainer.hasAttribute('data-company-org-number') ? checkoutContainer.dataset.companyOrgNumber : false;
        if( validateCompanyOnPageLoad ) {
            if( companyOrgNumber ) {
                spinner.show();
                await handleCompanyNegotiationWithTwo(companyOrgNumber);
                spinner.hide();
            } else {
                if( paymentInputInvoice instanceof HTMLElement ) {
                    paymentInputInvoice.disabled = true;
                }
            }
        }
    }
    if( companyNameInput instanceof HTMLInputElement && companyNameDataList instanceof HTMLDataListElement && organizationNumberInput instanceof HTMLInputElement ) {
        companyNameInput.addEventListener('keyup', async(e) => {
            // have to validate this since otherwise when I select an option with my mouse it fires this event again
            if( e instanceof KeyboardEvent ) {
                handleCompanyNameOrOrgNumberChange(e);
                companyNameInput.setAttribute('data-selected', 'false');
                companyNameInput.setCustomValidity('please select from the company list.');
                companyNameInput.classList.remove('is-valid');
                companyNameInput.classList.add('is-invalid');
            }
        });

        companyNameInput.addEventListener('input', async (e) => {
            //trigger change event even if using the same value
            companyNameInput.dispatchEvent(new Event('change'));
        });

        companyNameInput.addEventListener('change', async(e) => {
            let selectedOption;
            const companyName = e.target.value;
            [...companyNameDataList.options].map(option => {
                if( option.value === companyName ) {
                    selectedOption = option;
                }
            });
            if( selectedOption instanceof HTMLElement ) {
                spinner.show();
                const orgNumber = selectedOption.dataset.id;
                await handleCompanyNegotiationWithTwo(orgNumber, null, true);
                spinner.hide();
                companyNameInput.classList.add('is-valid');
                companyNameInput.classList.remove('is-invalid');
                companyNameInput.setAttribute('data-selected', 'true');
                companyNameInput.setCustomValidity('');
                companyNameInput.reportValidity();
            }
        });

        // We seemingly can't show datalist if it's being populated from another input (not the one which is tied to it)
        //organizationNumberInput.addEventListener('keyup', async (e) => handleCompanyNameOrOrgNumberChange(e));
    }
    
    if( submitButton instanceof HTMLElement ) {
        submitButton.addEventListener('click', submitOrder);
    }
}

const handleCompanyNameOrOrgNumberChange = async(e) => {
    const enabledForCheckoutField = document.querySelector('input[name="fields[twoIsEnabledForCheckout]"]');
    if( paymentInputInvoice instanceof HTMLElement && paymentInputBankCard instanceof HTMLElement  && enabledForCheckoutField instanceof HTMLInputElement ) {
        paymentInputInvoice.disabled = true;
        paymentInputInvoice.checked = false;
        enabledForCheckoutField.value = '0';
    }
    const inputString = e.target.value;
    if( !isValidKeypress(e.code) ) {
        return;
    } 
    while( companyNameDataList.children.length ) {
        companyNameDataList.children[0].remove();
    }
    if( abortControllers.length ) {
        abortControllers.map(controller => controller.abort());
    }
    const abortController = new AbortController();
    abortControllers = [abortController];

    if( inputString.length > 2 ) {
        const companyLookupResponse = await queryCompaniesBySearchTerm(inputString, abortController);
        if( companyLookupResponse.response.success ) {
            updateCompanyNameDataListOptions(companyLookupResponse.response.items);
        }
    }
};

const updateCompanyNameDataListOptions = (companyItems = []) => {
    for ( const companyData of companyItems.slice(0, 10) ) {
        const optionElement = document.createElement('option');
        optionElement.value = companyData.name;
        optionElement.dataset.id = companyData.id;
        if( companyNameDataList instanceof HTMLDataListElement ) {
            companyNameDataList.appendChild(optionElement);
        }
    }
}

const updateCompanyFields = (companyAddressData, orgNumber) => {
    const orgNumberInput = document.getElementById('form__business__input__billingCompanyOrganizationNumber');
    const billingAddressInput = document.getElementById('form__business__input__billingAddress_addressLine1');
    const billingAddress2Input = document.getElementById('form__business__input__billingAddress_addressLine2');
    const billingPostalCodeInput = document.getElementById('form__business__input__billingAddress_postalCode');
    const billingPostalPlaceInput = document.getElementById('form__business__input__billingAddress_locality');
    
    let validElements = true;
    [orgNumberInput, billingAddressInput, billingAddress2Input, billingPostalCodeInput, billingPostalPlaceInput]
        .map(element => {
            if( element instanceof HTMLElement ) {
                if( element.hasAttribute('data-readonly-after-company-lookup') ) {
                    element.readOnly = true;
                }
            } else {
                validElements = false;
            }
    });
    
    if( validElements ) {
        orgNumberInput.value = orgNumber ?? '';
        billingAddressInput.value = companyAddressData.streetAddress ?? '';
        billingPostalCodeInput.value = companyAddressData.postalCode ?? '';
        billingPostalPlaceInput.value = companyAddressData.postalPlace ?? '';
    }
}

const validateUserInputs = () => {
    let valid = false;
    if( paymentForms.length ) {
        [...paymentForms].map( formElement => {
            if( formElement.parentNode.style.display !== 'none' && formElement.hasAttribute('novalidate') ) {
                formElement.classList.add('was-validated');
                valid = formElement.checkValidity();
            }
        });
    }
    return valid;
}

const initFormValidityHandler = () => {
    if( paymentForms.length ) {
        [...paymentForms].map( formElement => {
            const inputs = formElement.querySelectorAll('[required]');
            if( inputs.length ) {
                [...inputs].map(input => {
                    input.addEventListener('change', (e) => {
                        const parentForm = input.closest('form');
                        if( parentForm instanceof HTMLFormElement && parentForm.classList.contains('was-validated') ) {
                            parentForm.classList.remove('was-validated');                            
                        }
                    });
                });
            }
        });
    } 
}

const initPrivatBedriftTogglers = () => {
    // References to radio buttons
    const privateRadio = document.getElementById('form__userType__private');
    const businessRadio = document.getElementById('form__userType__business');
    if( !(privateRadio instanceof HTMLInputElement) && !(businessRadio instanceof HTMLInputElement) ) {
        return;
    }
    
    const privateRadioLabel = document.querySelector('label[for="form__userType__private"]');
    if( privateRadioLabel instanceof HTMLLabelElement && privateRadioLabel.hasAttribute('data-create-popover') ) {
        const privateRadioLabelPopover = createPopover(privateRadioLabel);
        privateRadioLabel.addEventListener('click', (e) => {
            if( privateRadio.disabled ) {
                privateRadioLabelPopover.show();
            }
        });
    }

    // References to the elements you want to show/hide
    const userTypePrivate = document.querySelector('.usertype--private');
    const userTypeBusiness = document.querySelector('.usertype--business');

    // Event handler function
    function toggleVisibility() {
        if (privateRadio.checked) {
            userTypePrivate.style.display = 'block';
            userTypeBusiness.style.display = 'none';
            
            if( paymentInputInvoice instanceof HTMLInputElement ) {
                paymentInputInvoice.disabled = true;
            }
            
        } else if (businessRadio.checked) {
            userTypePrivate.style.display = 'none';
            userTypeBusiness.style.display = 'block';

            if( paymentInputInvoice instanceof HTMLInputElement ) {
                paymentInputInvoice.disabled = false;
            }
            
        }
    }

    // Attach event listeners to the radio buttons
    privateRadio.addEventListener('change', toggleVisibility);
    businessRadio.addEventListener('change', toggleVisibility);

    // Call the function once to set the initial state
    toggleVisibility();
}

const handleCompanyNegotiationWithTwo = async(organizationNumber = null, companyName = null, updateCompanyInputs = false) => {
    if( organizationNumber === null && companyName === null ) {
        throw "Both organization number and company name was null. Have to provide one of the for lookup!";
    }
    
    const companyQueryResponse = await queryCompaniesBySearchTerm(organizationNumber ?? companyName, new AbortController());
    let companyFound = companyQueryResponse.response.success && companyQueryResponse.response.items.length;
    if( organizationNumber !== null ) {
        companyFound = companyFound && parseInt(companyQueryResponse.response.items[0].id) === parseInt(organizationNumber);
    }
    if( ! companyFound ) {
        showToast(`Kunne ikke finne selskapet etter oppgitt organisasjonsnummer. (${organizationNumber}) Ta kontakt med Lindbak for å kunne benytte fakturabetaling.`, 'Error', 5000);
        return;
    }
    const company = companyQueryResponse.response.items[0];
    
    if( updateCompanyInputs ) {
        const companyAddressResponse = await queryCompanyAddressByOrgNumber(company.id);
        if( companyAddressResponse.response.success ) {
            updateCompanyFields(companyAddressResponse.response.address, company.id);
        }
    }
    
    const companyValidationResponse = await validateCompany(company.id, company.name);
    const enabledForCheckout = companyValidationResponse.response.success && companyValidationResponse.response.approved;

    if( paymentInputInvoice instanceof HTMLInputElement && paymentLabelInvoice instanceof HTMLLabelElement ) {
        paymentInputInvoice.disabled = !enabledForCheckout;
        if( paymentLabelInvoicePopover instanceof Popover ) {
            if( enabledForCheckout ) {
                setPopoverContent(paymentLabelInvoicePopover, paymentLabelInvoice.dataset.bsDefaultContent);
                paymentLabelInvoicePopover.disable();
            } else {
                setPopoverContent(paymentLabelInvoicePopover, paymentLabelInvoice.dataset.bsRefusedCompanyContent);
                paymentLabelInvoicePopover.enable();
            }
        }
    }

    const companyField = document.querySelector('input[name="fields[twoCompany]"]');
    const enabledForCheckoutField = document.querySelector('input[name="fields[twoIsEnabledForCheckout]"]');
    
    if( companyField instanceof HTMLInputElement  && enabledForCheckoutField instanceof HTMLInputElement ) {
        enabledForCheckoutField.value = enabledForCheckout ? '1' : '0';
        companyField.value = JSON.stringify({
            company_name: company.name,
            country_prefix: 'NO',
            organization_number: company.id
        });
    }
}

const isValidKeypress = (keyCode) => {
    // Hopefully this is enough - Can't use keypress event unfortunately which seems to have this kind of filtering, since that happens before the input changes (excludes the last character)..
    const ignoredKeyCodes = ['Backquote', 'Escape', 'ControlLeft', 'ControlRight', 'AltLeft', 'AltRight', 'Enter', 'CapsLock', 'Tab', 'ShiftLeft', 'ShiftRight',
        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'PageUp', 'PageDown', 'Home', 'End' ];
    return !ignoredKeyCodes.includes(keyCode);
}

const getCurrentForm = () => {
    let currentForm;
    [...paymentForms].map(formElement => {
        if( formElement.parentNode.style.display === 'block' ) {
            currentForm = formElement;
        }
    });
    return currentForm;
}

const getShippingForm = () => {
    return document.querySelector('.block__shop__checkout__shipping form');
}

const getPaymentForm = () => {
    return document.querySelector('.block__shop__checkout__payment form'); 
}

const submitOrder = async() => {
    let isPaymentInputSelected = false;
    let businessRadioButton = document.getElementById('form__userType__business');
    if( ( companyNameInput.getAttribute('data-selected') == "false" || !companyNameInput.value ) && businessRadioButton.checked ){
        companyNameInput.setCustomValidity('please select from the company list.');
        companyNameInput.reportValidity();
    }
    if( !validateUserInputs() ) {
        //Toast about validating inputs
        showToast('Vennligst sjekk og korriger inndata!', 'Error', true);
        return;
    }

    const paymentSelectionFormInputs = document.querySelectorAll('.block__shop__checkout__payment__body form input');
    if( paymentSelectionFormInputs.length ) {
        [...paymentSelectionFormInputs].map(el => {
            if( el.checked ) {
                isPaymentInputSelected = true;
            }
        });
        if( !isPaymentInputSelected ) {
            //Toast about selecting payment method
            showToast('Betalingsleverandør må velges!', 'Error', true);
            return;
        }
    }
    
    try {
        spinner.show();
        
        const currentForm = getCurrentForm();
        if(! currentForm instanceof HTMLFormElement ) {
            throw "Couldn't determine currently used form element!";
        }
        
        // updating hidden billing and shipping address fields
        updateHiddenAddressFields(currentForm);
        
        const updateCartResponse = await postFormData('/actions/commerce/cart/update-cart', currentForm);
        if( updateCartResponse.response.hasOwnProperty('errors') ) {
            throw {errors: updateCartResponse.response.errors, message: updateCartResponse.response.message};
        }
        
        const updateCustomCartAddressFieldsResponse = await updateCustomCartAddressFields();
        if( !updateCustomCartAddressFieldsResponse.response.success ) {
            throw updateCustomCartAddressFieldsResponse.response.error;
        }

        const shippingForm = getShippingForm();
        if(! shippingForm instanceof HTMLFormElement ) {
            throw "Couldn't query shipping form!";
        }
        const updateShippingResponse = await postFormData('/actions/commerce/cart/update-cart', shippingForm);
        if( updateShippingResponse.response.hasOwnProperty('errors') ) {
            throw {errors: updateShippingResponse.response.errors, message: updateShippingResponse.response.message};
        }

        const paymentForm = getPaymentForm();
        if(! paymentForm instanceof HTMLFormElement ) {
            throw "Couldn't query payment form!";
        }
        const updatePaymentResponse = await postFormData('/actions/commerce/cart/update-cart', paymentForm);
        if( updatePaymentResponse.response.hasOwnProperty('errors') ) {
            throw {errors: updatePaymentResponse.response.errors, message: updatePaymentResponse.response.message};
        }
        
        const customerCardIdInput = currentForm.querySelector('[name="customerCardId"]');
        if( customerCardIdInput instanceof HTMLInputElement ) {
            const customerCardId = customerCardIdInput.value;
            const updateCustomerCardOnCartResponse = await createPostRequest('/webshop/set-customer-card-on-cart', {customerCardId});
            if( !updateCustomerCardOnCartResponse.response.success ) {
                throw updateCustomerCardOnCartResponse.response.error;
            }
        }
        
        const redirectUrl = submitButton.hasAttribute('data-continue-checkout-url') ? submitButton.getAttribute('data-continue-checkout-url') : false;
        if( redirectUrl && redirectUrl.length ) {
            // Send GTM add_shipping_info event
            await GTMAddShippingInfo();
            
            window.location.assign(redirectUrl);
        }

    } catch (e) {
        spinner.hide();
        if( e instanceof Object ) {
            if( e.hasOwnProperty('errors') ) {
                for (const [key, value] of Object.entries(e.errors)) {
                    showToast(`[${key}] - ${value} `, e.message, true, true, 10000);
                }
            }
        } else {
            console.error(e);
            showToast("Det oppsto en feil under lagring av handlekurvinformasjon. Ta kontakt med Lindbak.", 'Error', true);
        }
    }
}

const updateHiddenAddressFields = (currentForm) => {
    const firstNameInput = currentForm.querySelector('input[name="fields[firstName]"]');
    const lastNameInput = currentForm.querySelector('input[name="fields[lastName]"]');
    const billingFirstNameInput = currentForm.querySelector('input[name="billingAddress[firstName]"]');
    const billingLastNameInput = currentForm.querySelector('input[name="billingAddress[lastName]"]');
    const shippingFirstNameInput = currentForm.querySelector('input[name="shippingAddress[firstName]"]');
    const shippingLastNameInput = currentForm.querySelector('input[name="shippingAddress[lastName]"]');

    if( firstNameInput instanceof HTMLInputElement && lastNameInput instanceof HTMLInputElement && billingFirstNameInput instanceof HTMLInputElement && billingLastNameInput instanceof HTMLInputElement && shippingFirstNameInput instanceof HTMLInputElement && shippingLastNameInput instanceof HTMLInputElement) {
        billingFirstNameInput.value = firstNameInput.value;
        billingLastNameInput.value = lastNameInput.value;
        shippingFirstNameInput.value = firstNameInput.value;
        shippingLastNameInput.value = lastNameInput.value;
    }
}

const updateCustomCartAddressFields = async() => {
    const form = getCurrentForm();
    const phoneField = form.querySelector('input[name="fields[phone]"]');
    if( phoneField instanceof HTMLInputElement ) {
        return await createPostRequest('/webshop/update-custom-cart-address-fields', {phone: phoneField.value});
    } else {
        throw "Couldn't find phone field!";
    }
}
