import {Swiper, Navigation, Pagination, Scrollbar } from 'swiper';
import { initSwiperVueApps } from "./vue-apps";

Swiper.use([Navigation, Pagination, Scrollbar]);

export function initSwiper() {
    document.addEventListener('DOMContentLoaded', () => {
        const slider = document.querySelector('.block__shop__category__slide .slider-wrapper');
        const productListBlocks = document.querySelectorAll('.matrix-products-block');
        initSwiperVueApps(slider, productListBlocks);
        init(slider, productListBlocks);
    });
}

function init(slider, productListBlocks) {
    
    if( slider instanceof HTMLElement ) {
        let isDown = false;
        let startX;
        let scrollLeft;
        
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            cancelMomentumTracking();
        });

        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
            beginMomentumTracking();
        });

        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX); //scroll-fast
            var prevScrollLeft = slider.scrollLeft;
            slider.scrollLeft = scrollLeft - walk;
            velX = slider.scrollLeft - prevScrollLeft;
        });

        // Momentum 
        var velX = 0;
        var momentumID;

        //slider.addEventListener('wheel', (e) => {
        //    cancelMomentumTracking();
        //});
    }
    
    function beginMomentumTracking(){
        cancelMomentumTracking();
        momentumID = requestAnimationFrame(momentumLoop);
    }
    
    function cancelMomentumTracking(){
        cancelAnimationFrame(momentumID);
    }
    
    function momentumLoop(){
        slider.scrollLeft += velX * 2;
        velX *= 0.95; 
        if (Math.abs(velX) > 0.5){
            momentumID = requestAnimationFrame(momentumLoop);
        }
    }
    
    //Scroll
    //const scrollContainer = document.querySelector(".block__shop__category__slide .slider-wrapper");
    
    //if( scrollContainer instanceof HTMLElement ) {
    //    scrollContainer.addEventListener("wheel", (evt) => {
    //        evt.preventDefault();
    //        scrollContainer.scrollLeft += evt.deltaY;
    //    });   
    //}

    if (productListBlocks.length) {
        [...productListBlocks].forEach(block => {
            if (block instanceof HTMLElement) {
                const swiperList = block.querySelector('.swiper.list');
                if (swiperList instanceof HTMLElement) {
                    const swiperPrevElement = block.querySelector('.custom-prev');
                    const swiperNextElement = block.querySelector('.custom-next');
                    const swiper = new Swiper(swiperList, {
                        modules: [Navigation],
                        slidesPerView: 1,
                        spaceBetween: 20,
                        breakpoints: {
                            "576": {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            "768": {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            "991": {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            "1200": {
                                slidesPerView: 4,
                            },
                        },
                        navigation: {
                            nextEl: swiperNextElement,
                            prevEl: swiperPrevElement
                        },
                    });
                }
            }
        });
    }
}