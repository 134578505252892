import {createPostRequest, addDynamicEventListener, getClosest, getSpinner} from "./helpers";
import noUiSlider from "nouislider";
import {GTMViewItemList} from "./analytics";

let filters = {};
let productList;
let productCategorySlider;
let offCanvasFilterModal;
let headerFooter;
const filtersToSerialize = ['width', 'height', 'length'];
let sliderLength, sliderWidth, sliderHeight;
let abortControllers = [];
let lastFilterInput;
let resetFilters = false;
let spinnerIsVisible = false;
let clearSlideInActiveFilterBtn;

export function initWebshop() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

async function init() {
    productList = document.querySelector('.block__shop__product__list');
    productCategorySlider = document.querySelector('.block__shop__category__slide')
    offCanvasFilterModal = document.getElementById('offcanvasAllFilters');
    headerFooter = document.querySelector('.block__header__footer');
    clearSlideInActiveFilterBtn = document.querySelector('#offcanvasAllFilters .link--resetFilter');
    
    if( productList instanceof HTMLElement && offCanvasFilterModal instanceof HTMLElement ) {
        // addDynamicEventListener(productList, 'click', 'div.product-variants a', (e) => variantClickHandler(e) );
        // addDynamicEventListener(productList, 'click', '.block__footer .list__pagination a.bttn', async (e) => await queryProducts(e, true) );
        // addDynamicEventListener(productList, 'click', '.block__header__tools a.sort-filter', sortHandler );
        // addDynamicEventListener(productList, 'click', 'input[name="contractedProducts"]', contractedProductInputHandler );
        
        // addDynamicEventListener(offCanvasFilterModal, 'click', '.link--resetFilter', clearActiveFilters );
        // addDynamicEventListener(offCanvasFilterModal, 'click', '#allFilters input[id^="product-filter"]', filterInputHandler );
        // addDynamicEventListener(offCanvasFilterModal, 'click', '.filters-active a', removeActiveFilter );
        // addDynamicEventListener(offCanvasFilterModal, 'click', '.filter--category .has-children > .form-check > .form-check-label span', multilevelFilterToggle);
        
        if( headerFooter instanceof HTMLElement ) {
            // addDynamicEventListener(headerFooter, 'click', 'a.reset-filter', clearActiveFilters );
            // addDynamicEventListener(headerFooter, 'click', 'a.active-filter', removeActiveFilter );
        }

        updateFiltersBasedOnUrl();
        if (productList.dataset.productCategoryId) {
            filters.productCategoryId = productList.dataset.productCategoryId;
        }
        
        // initNoUISliders();
        
        // send GTM view_list event
        if( productList.dataset.hasOwnProperty('purchasableIds') ) {
            const purchasableIds = JSON.parse(productList.dataset.purchasableIds);
            if( purchasableIds.length ) {
                if( productList.dataset.hasOwnProperty('productCategoryTitle') ) {
                    await GTMViewItemList('webshop_product_category_page', `Webshop product category page load - ${productList.dataset.productCategoryTitle}`, purchasableIds);
                } else {
                    await GTMViewItemList('webshop', 'Webshop page load', purchasableIds);
                }
            }
        }
    }
}

const multilevelFilterToggle = (event) => {
    event.preventDefault();
    const li = getClosest(event.target, 'li');
    li.classList.toggle('open');
}

const contractedProductInputHandler = async (e) => {
    const checked = e.target.checked;
    if( checked ) {
        filters['contractedProducts'] = 1;
    } else {
        if( filters.hasOwnProperty('contractedProducts') ) {
            delete filters['contractedProducts'];
        }
    }
    updateUrlBasedOnFilters();
    await queryProducts();
}
const sortHandler = async (e) => {
    e.preventDefault();
    const target = e.target.nodeName === 'A' ? e.target : getClosest(e.target, 'a.sort-filter');
    if( target instanceof HTMLElement ) {
        filters.sort = target.dataset.sort;
        updateUrlBasedOnFilters();
        await queryProducts();
    }
}

const addActiveFilter = (filterInput, sizeFilter = null) => {
    let activeFilterList = offCanvasFilterModal.querySelector('.filters-active ul');
    if( activeFilterList === null ) {
        activeFilterList = document.createElement('div');
        activeFilterList.classList.add('filters-active')
        const ul = document.createElement('ul');
        activeFilterList.appendChild(ul);
        const allFilters = offCanvasFilterModal.querySelector('#allFilters');
        allFilters.parentNode.insertBefore(activeFilterList, allFilters);
        activeFilterList = ul;
    }
    
    let appendHTML = true;
    let html;
    if( sizeFilter === null ) {
        html = `<li><a href="" class="active-filter" data-category-id="${filterInput.value}" data-category-group-handle="${filterInput.dataset.categoryGroupHandle}" data-filter-type="input">${filterInput.dataset.filterTitle.toUpperCase()}</a></li>`;
    } else {
        const activeFilters = document.querySelectorAll('.filters-active .active-filter');
        const filterName = sizeFilter.dataset.filterName;
        const visibleName = sizeFilter.dataset.visibleName;
        const sizes = sizeFilter.noUiSlider.get(true);
        const found = [...activeFilters].find(el => el.dataset.filterType === 'slider' && el.dataset.filterName === filterName);

        html = `<a href="" class="active-filter" data-category-id="0" data-filter-type="slider" data-filter-name="${filterName}">${visibleName.toUpperCase()} (${parseInt(sizes[0])} - ${parseInt(sizes[1])})</a>`;

        if( found instanceof HTMLElement ) {
            appendHTML = false;
            found.outerHTML = html;
        } else {
            html = `<li>${html}</li>`
        }
    }
    
    if( appendHTML ) {
        activeFilterList.insertAdjacentHTML('beforeend', html);
    }
    
    if( clearSlideInActiveFilterBtn instanceof HTMLElement ) {
        clearSlideInActiveFilterBtn.classList.remove('d-none');
    }
}
const removeActiveFilter = async (e = null, categoryId = null) => {
    let target;
    
    if( e === null && categoryId === null ) {
        return;
    } else if(e !== null) {
        e.preventDefault();
        target = e.target.nodeName === 'A' ? e.target : getClosest('a.active-filter');
    } else {
        target = offCanvasFilterModal.querySelector(`a.active-filter[data-category-id="${categoryId}"]`);
    }

    if( target instanceof HTMLElement ) {
        if( target.parentNode ) {
            target.parentNode.remove();
        }
        
        const filterType = target.dataset.filterType;
        if( filterType === 'input' ) {
            const categoryId = target.dataset.categoryId;
            updateFilterInput(categoryId, false);
        } else if( filterType === 'slider' ) {
            const filterName = target.dataset.filterName;
            if( filters.hasOwnProperty(filterName) ) {
                delete filters[filterName];
            }
            resetNoUISliders(filterName);
            updateUrlBasedOnFilters();
            await queryProducts();
        }

        const activeFilters = offCanvasFilterModal.querySelectorAll('.active-filter');
        if( activeFilters.length === 0 ) {
            const filtersActive = offCanvasFilterModal.querySelector('.filters-active');
            if( filtersActive instanceof HTMLElement ) {
                filtersActive.remove();
            }
            if( clearSlideInActiveFilterBtn instanceof HTMLElement ) {
                clearSlideInActiveFilterBtn.classList.add('d-none');
            }
        }
    }
}
const clearActiveFilters = async (e) => {
    e.preventDefault();
    const activeFilters = offCanvasFilterModal.querySelectorAll('.active-filter');
    if( activeFilters.length ) {
        [...activeFilters].map(el => {
            const categoryId = el.dataset.categoryId;
            updateFilterInput(categoryId, false, false);
            el.parentNode.remove();
        });
        
        if( clearSlideInActiveFilterBtn instanceof HTMLElement ) {
            clearSlideInActiveFilterBtn.classList.add('d-none');
        }

        const productCategoryId = filters.productCategoryId;
        const searchTerm = filters.q;
        filters = {
            productCategoryId,
            q: searchTerm
        };
        updateUrlBasedOnFilters();
        resetNoUISliders();
        resetFilters = true;
        await queryProducts();
    }
}

const filterInputHandler  = (e) => {
    const target = e.target.nodeName === 'INPUT' ? e.target : getClosest(e.target, 'input[id^="product-filter"]');
    if( target instanceof HTMLElement ) {
        lastFilterInput = target;
        const categoryId = target.value;
        if( target.checked ) {
            addActiveFilter(target);
            addCategoryToFilters(target.dataset.filterName, categoryId, true);
        } else {
            removeActiveFilter(null, categoryId);
            removeCategoryIdFromFilters(categoryId, true );
        }
    }
}

const updateFilterInput = (categoryId, checked = true, fetchProducts = true) => {
    const filterInput = offCanvasFilterModal.querySelector(`input[value="${categoryId}"]`);
    if( filterInput instanceof HTMLElement ) {
        filterInput.checked = checked;
        if( checked ) {
            addCategoryToFilters(filterInput.dataset.filterName, categoryId, fetchProducts);
        } else {
            removeCategoryIdFromFilters(categoryId, fetchProducts);
        }
    } else {
        // filter input was probably already removed from the DOM based on other filters, we remove it from our filter object and query products to update DOM
        removeCategoryIdFromFilters(categoryId, true);
    }
}

// wishlist page uses this as well, be mindful and test when modifying this
export const variantClickHandler = async (e) => {
    const target = e.target.nodeName === 'LI' ? e.target : getClosest(e.target, '.variant-list-item');
    if( target instanceof HTMLElement ) {
        e.preventDefault();
        e.stopPropagation();
        
        if( target.classList.contains('active') ) {
            return;
        }
        
        const variantId = target.dataset.variantId;
        const response = await createPostRequest('/webshop/get-product-list-item-template', {variantId});
        if( response.success ) {
            const article = getClosest(target, 'article');
            if( article instanceof HTMLElement) {
                article.outerHTML = response.response.html;
            }
        } else {
            console.error(response);
        }
    }
}

const resetNoUISliders = (filterName = null) => {
    if( sliderHeight instanceof HTMLElement && sliderLength instanceof HTMLElement && sliderWidth instanceof HTMLElement ) {
        let sliders = [];
        if( filterName === null ) {
            sliders = [sliderWidth, sliderHeight, sliderLength];
        } else {
            sliders = [document.getElementById(`range-slider--${filterName}`)];
        }
        sliders.map(slider => {
            slider.noUiSlider.set([0, slider.dataset.maxValue ?? 500]);
        })
    }
}

const initNoUISliders = () => {
    // Filter Size - Range slider - Height
    sliderHeight = document.getElementById('range-slider--height');
    const minValueHeight = document.querySelector('.range-slider--height__min');
    const maxValueHeight = document.querySelector('.range-slider--height__max');

    if( sliderHeight instanceof HTMLElement ) {
        const maxHeight = sliderHeight.dataset['maxValue'] ? parseInt(sliderHeight.dataset.maxValue) : 500;
        let selectedMaxHeight = sliderHeight.dataset['maxSelectedValue'] ? parseInt(sliderHeight.dataset.maxValue) : maxHeight;
        let selectedMinHeight = 0;

        if( filters.hasOwnProperty('height') ) {
            selectedMaxHeight = filters.height.max;
            selectedMinHeight = filters.height.min;
        }

        noUiSlider.create(sliderHeight, {
            start: [selectedMinHeight, selectedMaxHeight],
            connect: true,
            range: {
                'min': 0,
                'max': maxHeight
            },
            step: 1,
        });

        // Add an event listener for the update event
        sliderHeight.noUiSlider.on('update', function (values, handle) {
            minValueHeight.innerHTML = Math.round(values[0]) + " cm";
            maxValueHeight.innerHTML = Math.round(values[1]) + " cm";
        });

        sliderHeight.noUiSlider.on('end', async (values) => {
            const newValues = {
                min: parseInt(values[0]),
                max: parseInt(values[1])
            };

            addActiveFilter(null, sliderHeight);
            
            if(!filters.hasOwnProperty('height')) {
                filters['height'] = {};
            }
            filters.height = newValues;
            updateUrlBasedOnFilters();
            await queryProducts();
        });
    }

    // Filter Size - Range slider - Width
    sliderWidth = document.getElementById('range-slider--width');
    const minValueWidth = document.querySelector('.range-slider--width__min');
    const maxValueWidth = document.querySelector('.range-slider--width__max');

    if( sliderWidth instanceof HTMLElement ) {
        const maxWidth = sliderWidth.dataset['maxValue'] ? parseInt(sliderWidth.dataset.maxValue) : 500;
        let selectedMaxWidth = sliderWidth.dataset['maxSelectedValue'] ? parseInt(sliderWidth.dataset.maxValue) : maxWidth;
        let selectedMinWidth = 0;

        if( filters.hasOwnProperty('width') ) {
            selectedMaxWidth = filters.width.max;
            selectedMinWidth = filters.width.min;
        }

        noUiSlider.create(sliderWidth, {
            start: [selectedMinWidth, selectedMaxWidth],
            connect: true,
            range: {
                'min': 0,
                'max': maxWidth
            },
            step: 1,
        });

        // Add an event listener for the update event
        sliderWidth.noUiSlider.on('update', function (values, handle) {
            minValueWidth.innerHTML = Math.round(values[0]) + " cm";
            maxValueWidth.innerHTML = Math.round(values[1]) + " cm";
        });

        sliderWidth.noUiSlider.on('end', async (values) => {
            const newValues = {
                min: parseInt(values[0]),
                max: parseInt(values[1])
            };

            addActiveFilter(null, sliderWidth);
            
            if(!filters.hasOwnProperty('width')) {
                filters['width'] = {};
            }
            filters.width = newValues;
            updateUrlBasedOnFilters();
            await queryProducts();
        });
    }

    // Filter Size - Range slider - Length
    sliderLength = document.getElementById('range-slider--length');
    const minValueLength = document.querySelector('.range-slider--length__min');
    const maxValueLength = document.querySelector('.range-slider--length__max');

    if( sliderLength instanceof HTMLElement ) {
        const maxLength = sliderLength.dataset['maxValue'] ? parseInt(sliderLength.dataset.maxValue) : 500;
        let selectedMaxLength = sliderLength.dataset['maxSelectedValue'] ? parseInt(sliderLength.dataset.maxValue) : maxLength;
        let selectedMinLength = 0;

        if( filters.hasOwnProperty('length') ) {
            selectedMaxLength = filters.length.max;
            selectedMinLength = filters.length.min;
        }

        noUiSlider.create(sliderLength, {
            start: [selectedMinLength, selectedMaxLength],
            connect: true,
            range: {
                'min': 0,
                'max': maxLength
            },
            step: 1,
        });

        // Add an event listener for the update event
        sliderLength.noUiSlider.on('update', function (values, handle) {
            minValueLength.innerHTML = Math.round(values[0]) + " cm";
            maxValueLength.innerHTML = Math.round(values[1]) + " cm";
        });

        sliderLength.noUiSlider.on('end', async (values) => {
            const newValues = {
                min: parseInt(values[0]),
                max: parseInt(values[1])
            };

            addActiveFilter(null,  sliderLength);
            
            if(!filters.hasOwnProperty('length')) {
                filters['length'] = {};
            }
            filters.length = newValues;
            updateUrlBasedOnFilters();
            await queryProducts();
        });
    }
}

const removeCategoryIdFromFilters = async (categoryId, fetchProducts = true) => {
    for (const key of Object.keys(filters)) {
        let value = filters[key];
        if( Array.isArray(value) && value.includes(categoryId) ) {
            filters[key] = value.filter(v => v !== categoryId);
        } else if( value === categoryId ) {
            filters[key] = '';
        }
    }
    filters.page = 1;
    updateUrlBasedOnFilters();
    if( fetchProducts ) {
        await queryProducts();
    }
}

const addCategoryToFilters = async (filterName, filterValue, fetchProducts = true) => {
    if( filterName ) {
        if( filters[filterName] === undefined ) {
            filters[filterName] = [];
        } else if( !Array.isArray(filters[filterName]) ) {
            filters[filterName] = [filters[filterName]];
        }
        
        filters[filterName].push(filterValue);
    }
    filters.page = 1;
    updateUrlBasedOnFilters();
    if( fetchProducts ) {
        await queryProducts();
    }
}

const updateUrlBasedOnFilters = () => {
    const currentFiltersInURL = Object.fromEntries(new URLSearchParams(window.location.search));
    const modifiedUrl = new URL(location.href);
    for (const key of Object.keys(filters)) {
        if( filtersToSerialize.includes(key) ) {
            modifiedUrl.searchParams.set(key, JSON.stringify(filters[key]));
        } else if (filters[key]) {
            modifiedUrl.searchParams.set(key, filters[key]);
        }
    }
    
    for (const key of Object.keys(currentFiltersInURL)) {
        if( !filters.hasOwnProperty(key) ) {
            modifiedUrl.searchParams.delete(key);
        }
    }
    
    history.pushState({}, '', modifiedUrl.toString());
    updateProductCategoryURLsBasedOnFilters();
}

const updateProductCategoryURLsBasedOnFilters = () => {
    const currentFiltersInURL = Object.fromEntries(new URLSearchParams(window.location.search));
    delete currentFiltersInURL['productCategoryId'];
    delete currentFiltersInURL['page'];
    const productCategoryContainer = document.querySelector('.block__shop__category__slide__wrapper');
    if( productCategoryContainer instanceof HTMLElement ) {
        const links = productCategoryContainer.querySelectorAll('a');
        [...links].map(link => {
            const originalUrl = link.dataset.originalUrl;
            const modifiedLinkUrl = new URL(originalUrl);
            for( const key of Object.keys(currentFiltersInURL) ) {
                if( currentFiltersInURL[key].length && ! ['productCategoryId', 'page'].includes(key) ) {
                    modifiedLinkUrl.searchParams.set(key, currentFiltersInURL[key]);
                }
            }
            link.href = modifiedLinkUrl.toString();
        });
    }
}

const updateFiltersBasedOnUrl = () => {
    filters = Object.fromEntries(new URLSearchParams(window.location.search));
    for (const key of Object.keys(filters)) {
        if( filtersToSerialize.includes(key) ) {
            filters[key] = JSON.parse(filters[key]);  
        } else {
            if( filters[key].includes(',') ) {
                filters[key] = filters[key].split(',');
            }
        }
    }
    
    if( filters['page'] === undefined ) {
        filters.page = parseInt(productList.dataset.page);
    }
}

const queryProducts = async (e = null, appendResult = false) => {
    if( e ) {
        e.preventDefault();
    }
    
    if( appendResult ) {
        ++filters.page;
    } else {
        filters.page = 1;
    }
    updateUrlBasedOnFilters();
    
    if( abortControllers.length ) {
        abortControllers.map(controller => controller.abort());
    }
    const abortController = new AbortController();
    abortControllers = [abortController];

    await showSpinner();
    
    const response = await createPostRequest('/webshop/get-products', filters, 'application/json', abortController.signal);
    if( response.success ) {
        const openedFilters = getOpenFilters();
        const productListContainer = productList.querySelector('.row.list');
        const html = response.response.data.html;
        const sortContainer = productList.querySelector('.block__header__wrapper__right');
        const productFooter = productList.querySelector('.block__footer');
        const filterHTML = (new DOMParser()).parseFromString(response.response.data.offcanvasFilterHtml, "text/html");
        const allCurrentOffcanvasFilters = document.getElementById('allFilters');
        const totalNumberOfFilters = response.response.data?.totalNumberOfFilters;
        
        openedFilters.productCategoryListItems.forEach(id => {
            const input = filterHTML.querySelector(`input[value="${id}"]`);
            if (input) {
                input.closest('li.has-children').classList.add('open');
            }
        });
        
        if (openedFilters.bootstrapAccordionId && allCurrentOffcanvasFilters instanceof HTMLElement) {
            // hack for not replacing currently opened up filter group - please come up with a clean solution in Vue.js :)
            const openedFilter = filterHTML.getElementById(openedFilters.bootstrapAccordionId);
            if( openedFilter instanceof HTMLElement ) {
                if( totalNumberOfFilters && totalNumberOfFilters > 0 ) {
                    const currentlySelectedAccordion = allCurrentOffcanvasFilters.querySelector(`#${openedFilters.bootstrapAccordionId}`);
                    if( currentlySelectedAccordion instanceof HTMLElement ) {
                        openedFilter.replaceWith(currentlySelectedAccordion);
                    }
                } else {
                    openedFilter.classList.add('show');
                }   
            }
        }

        // replacing offcanvas filters with new filters from the server
        const allNewOffcanvasFilters = filterHTML.getElementById('allFilters');
        if( allCurrentOffcanvasFilters instanceof HTMLElement && allNewOffcanvasFilters instanceof HTMLElement ) {
            allCurrentOffcanvasFilters.replaceWith(allNewOffcanvasFilters);
            initNoUISliders();
        }
        
        if( sortContainer ) {
            sortContainer.outerHTML = response.response.data.sortHtml;
        }
        
        if( productFooter ) {
            productFooter.innerHTML = response.response.data.footerHtml;
        }
        
        if( headerFooter instanceof HTMLElement ) {
            if( totalNumberOfFilters ) {
                const totalActiveFilters = parseInt(totalNumberOfFilters);
                const buttonNumb = headerFooter.querySelector('.button-numb');
                if( buttonNumb instanceof HTMLElement) {
                    buttonNumb.textContent = totalActiveFilters.toString();
                    if( totalActiveFilters > 0 ) {
                        buttonNumb.classList.remove('d-none');
                    } else {
                        buttonNumb.classList.add('d-none');
                    }
                }
            }
            if( response.response.data.hasOwnProperty('newActiveFilterHtml') ) {
                const newFilterContainer = headerFooter.querySelector('.block__header__filter-active');
                if( newFilterContainer instanceof HTMLElement ) {
                    newFilterContainer.innerHTML = response.response.data.newActiveFilterHtml;
                }
            }   
        }
        
        const originalOffcanvasActiveFiltersHTML = document.querySelector('.offcanvas-body .filters-active');
        const newOffcanvasActiveFiltersHTML = filterHTML.querySelector('.offcanvas-body .filters-active');
        if( originalOffcanvasActiveFiltersHTML instanceof HTMLElement && newOffcanvasActiveFiltersHTML ) {
            originalOffcanvasActiveFiltersHTML.replaceWith(newOffcanvasActiveFiltersHTML);
        }
        
        // this is probably deprecated so commenting it out at 2024.01.15.
        // const filterAccordions = filterHTML.querySelectorAll('.accordion-item .accordion-collapse');
        // [...filterAccordions].map(accordion => {
        //     let lastUpdatedAccordion = false;
        //     if( lastFilterInput instanceof HTMLElement ) {
        //         lastUpdatedAccordion = lastFilterInput.dataset.categoryGroupHandle === accordion.dataset.categoryGroupHandle;
        //     }
        //     const visibleAccordionBody = offCanvasFilterModal.querySelector(`#${accordion.id} .accordion-body`);
        //    
        //     // if we have found the related accordion and the last added filter was not inside it, we replace the filters
        //     if( visibleAccordionBody instanceof HTMLElement && (!lastUpdatedAccordion || resetFilters ) ) {
        //         visibleAccordionBody.outerHTML = accordion.querySelector('.accordion-body').outerHTML;
        //        
        //         // hack, we have to reinitialize these sliders / event handlers when we update the HTML
        //         if( accordion.dataset.categoryGroupHandle === 'sizes' ) {
        //             initNoUISliders();
        //         }
        //     }
        // });
        resetFilters = false;
        
        if( response.response.data.hasOwnProperty('productCategorySlider') && productCategorySlider instanceof HTMLElement ) {
            const newCategorySliderDocument = (new DOMParser()).parseFromString(response.response.data.productCategorySlider, "text/html");
            const newCategorySlider = newCategorySliderDocument.querySelector('.block__shop__category__slide');
            if( newCategorySlider instanceof HTMLElement ) {
                productCategorySlider.replaceWith(newCategorySlider);
                productCategorySlider = newCategorySlider;
            }
        }
        
        // send GTM view_list event
        if( response.response.data.hasOwnProperty('purchasableIds') ) {
            const selectedProductCategoryTitle = response.response.data.hasOwnProperty('selectedProductCategory') ? response.response.data.selectedProductCategory : false;
            if( !selectedProductCategoryTitle ) {
                await GTMViewItemList('webshop_load_more_products', 'Webshop user interaction', response.response.data.purchasableIds);
            } else {
                await GTMViewItemList('webshop_load_more_products_product_category_page', `Webshop user interaction on product category page - ${selectedProductCategoryTitle}`, response.response.data.purchasableIds);
            }
        }
        
        if( appendResult ) {
            productList.dataset.page = filters.page.toString();
            productListContainer.insertAdjacentHTML('beforeend', html);
        } else {
            productListContainer.innerHTML= html;
        }
        
        await hideSpinner();
    } else {
        // removed error log, since when the AbortController is being used to cancel the request we always end up logging some internal errors here
        //console.error(response);
    }
}

const getOpenFilters = () => {
    const filters = document.getElementById('allFilters');
    if( filters instanceof HTMLElement ) {
        const productCategoryListItems = [...filters.querySelectorAll('li.has-children.open')].map(listItem => listItem.querySelector('input.form-check-input').value);
        const bootstrapAccordionId = filters.querySelector('.accordion-collapse.show')?.id || null;
        return { productCategoryListItems, bootstrapAccordionId };
    }
}

const showSpinner = async () => {
    const spinner = await getSpinner();
    if( !spinnerIsVisible ) {
        spinner.show();
        spinnerIsVisible = true;
    }
}
const hideSpinner = async () => {
    const spinner = await getSpinner();
    if( spinnerIsVisible ) {
        spinner.hide();
        spinnerIsVisible = false;
    }
};