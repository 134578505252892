import {createPostRequest, getClosest, getSpinner, showToast} from "./helpers";
import {GTMSendTagIds, GTMViewItemList} from "./analytics";

export function initBlocks() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

let spinner;
let lastArticleTag = '*';
let productsBlocks;
function init() {
    const articleListContainers = document.querySelectorAll('.block__list__articles');
    if( articleListContainers.length ) {
        [...articleListContainers].forEach(articleListContainer => {
            const id = articleListContainer.dataset.id;
            const filters = articleListContainer.querySelectorAll('.block__header__filter ul li');
            if( filters.length ) {
                [...filters].forEach(filter => {
                    filter.addEventListener('click', (event) => articleListFilterHandler(event, id, filter.dataset.tagId, articleListContainer));
                })
            }
            
            const showMoreButton = articleListContainer.querySelector('.bttn-show-more');
            const morePages = articleListContainer.dataset.lastPage !== '1';
            if( showMoreButton instanceof HTMLAnchorElement && morePages ) {
                showMoreButton.addEventListener('click', (event) => loadMoreArticles(event, articleListContainer));
            }
        });
    }
    
    productsBlocks = document.querySelectorAll('.matrix-products-block');
    if( productsBlocks.length ) {
        initGTMViewItemLists();
    }
    
    // sending tag data from "sideTags" and "segmentTags" through GTM
    const articleAndPageTags = document.querySelector('.page-and-article-tags');
    if( articleAndPageTags instanceof HTMLElement ) {
        let pageTags = [];
        let pageTagNames = [];
        if( articleAndPageTags.dataset.hasOwnProperty('pageTags') ) {
            pageTags = JSON.parse(articleAndPageTags.dataset.pageTags);
        }
        if( articleAndPageTags.dataset.hasOwnProperty('pageTagNames') ) {
            pageTagNames = JSON.parse(articleAndPageTags.dataset.pageTagNames);
        }
        
        let segmentTags = [];
        let segmentTagNames = [];
        if( articleAndPageTags.dataset.hasOwnProperty('segmentTags') ) {
            segmentTags = JSON.parse(articleAndPageTags.dataset.segmentTags);
        }
        if( articleAndPageTags.dataset.hasOwnProperty('segmentTagNames') ) {
            segmentTagNames = JSON.parse(articleAndPageTags.dataset.segmentTagNames);
        }
        GTMSendTagIds(pageTags, segmentTags, pageTagNames, segmentTagNames);
    }
}

const initGTMViewItemLists = async () => {
    for( const block of productsBlocks ) {
        const items = block.querySelectorAll('article');
        if( items.length ) {
            let purchasableIds = [];
            items.forEach(article => purchasableIds.push(article.dataset.purchasableId ?? null));
            purchasableIds = purchasableIds.filter(v => v !== null);
            if( purchasableIds.length ) {
                // GTM view_item_list
                await GTMViewItemList('products_in_content_block', `Product listing block in page content`, purchasableIds);
            }
        }
    }
};

const articleListFilterHandler = async (event, listId, tagId, articleListContainer) => {
    event.preventDefault();
    event.stopPropagation();
    const clickedFilter = event.target.nodeName === 'LI' ? event.target : getClosest(event.target, 'li');
    if( clickedFilter instanceof HTMLLIElement ) {
        const allFilters = clickedFilter.parentNode.querySelectorAll('li');
        if( allFilters.length ) {
            [...allFilters].map(li => li.classList.remove('active'));
        }
        clickedFilter.classList.add('active');
    }
    
    await loadMoreArticles(event, articleListContainer);
}

const loadMoreArticles = async (event, articleListContainer) => {
    event.preventDefault();
    event.stopPropagation();
    
    const params = getArticleListParams(articleListContainer);
    let currentPage = parseInt(params.currentPage) + 1;
    if( lastArticleTag !== params.selectedTag ) {
        lastArticleTag = params.selectedTag;
        currentPage = 1;
    }
    params.currentPage = currentPage;
    
    if( !spinner ) {
        spinner = await getSpinner();
    }
    
    spinner.show();
    
    const response = await createPostRequest('/template/article-list', params);
    if ( response.response.success && response.response.hasOwnProperty('html') && response.response.hasOwnProperty('lastPage') ) {
        const articleContainer = articleListContainer.querySelector('.block__body .row.list');
        if( articleContainer instanceof HTMLElement ) {
            articleListContainer.dataset.page = (parseInt(params.currentPage) + 1).toString();
            articleListContainer.dataset.lastPage = response.response.lastPage ? "1" : "0";
            
            if( parseInt(currentPage) === 1 ) {
                articleContainer.querySelectorAll('.listelement:not(.listelement--featured)').forEach(listElement => listElement.remove());
            }
            articleContainer.insertAdjacentHTML('beforeend', response.response.html);

            const blockFooter = articleListContainer.querySelector('.block__footer');
            if( blockFooter instanceof HTMLElement ) {
                if( response.response.lastPage ) {
                    blockFooter.classList.add('d-none');
                } else {
                    blockFooter.classList.remove('d-none');
                }
            }
        }
    } else {
        showToast("Serveren kunne ikke behandle forespørselen.", "Error", true, true, 2000);
    }
    
    spinner.hide();
}

const getArticleListParams = (listContainer) => {
    const currentPage = listContainer.dataset.page ?? null;
    const entryId = listContainer.dataset.entryId ?? null;
    const limit = listContainer.dataset.limit ?? null;
    const tagIds = listContainer.dataset.tagIds ?? null
    const segmentTagIds = listContainer.dataset.segmentTagIds ?? null;
    const featuredArticleId = listContainer.dataset.featuredArticleId ?? null;
    const articleType = listContainer.dataset.articleType ?? null;
    const selectedTag = listContainer.querySelector('.block__header__filter li.active').dataset.tagId ?? '*';
    return {currentPage, entryId, limit, tagIds, segmentTagIds, featuredArticleId, selectedTag, articleType};
}