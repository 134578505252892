import {createPostRequest, serializeForm} from "./helpers";

export const queryCompaniesBySearchTerm = async(searchTerm, abortController) => {
    return await createPostRequest('/commerce-two/company-search/', {companyName: searchTerm}, 'application/json', abortController.signal);
}
export const queryCompanyAddressByOrgNumber = async(companyId) => {
    return await createPostRequest('/commerce-two/company-address/', {companyId});
}

export const validateCompany = async(companyId, companyName) => {
    return await createPostRequest('/commerce-two/company-check/', {companyId, companyName});
}

// For some reason this doesn't work
export const setCompanyOnCart = async(companyId, companyName, enabledForCheckout = true) => {
    return await createPostRequest('/commerce-two/set-company', {companyId, companyName, enabledForCheckout});
}

// Helper function for transforming normal Craft Commerce input name fields to what the Two controller is expecting
const transformFormValues = (form) => {
    const serialized = serializeForm(form);
    let payload = {};
    
    for(const key in serialized) {
        let newKey;
        if( key.includes('billingAddress') ) {
            const regex = /billingAddress\[(.*)\]/s;
            const match = key.match(regex);
            if( match.length === 2 ) {
                newKey = `billing${match[1].charAt(0).toUpperCase() + match[1].slice(1)}`;
                if( newKey.includes('Line') ) {
                    newKey = newKey.replace('Line', '');
                }
            }
        } else if( key.includes('shippingAddress') ) {
            const regex = /shippingAddress\[(.*)\]/s;
            const match = key.match(regex);
            if( match.length === 2 ) {
                newKey = `shipping${match[1].charAt(0).toUpperCase() + match[1].slice(1)}`;
                if( newKey.includes('Line') ) {
                    newKey = newKey.replace('Line', '');
                }
            }
        } else if( key.includes('fields') ) {
            const regex = /fields\[(.*)\]/s;
            const match = key.match(regex);
            if( match.length === 2 ) {
                newKey = match[1];
            }
        } else {
            newKey = key;
        }

        if( newKey.length ) {
            payload[newKey] = serialized[key];
        }
    }
    return payload;
}

export const setCustomerAddresses = async(form) => {
    const payload = transformFormValues(form);
    return await createPostRequest('/commerce-two/set-customer-addresses', payload);
}