<template>
    <div class="accordion-item">
        <h2 class="accordion-header" :id="'allFilters_heading_' + categoryNameKebab">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#allFilters_collapse_' + categoryNameKebab" aria-expanded="false"
                    :aria-controls="'allFilters_collapse_' + categoryNameKebab">
                {{ categoryName }}
            </button>
        </h2>
        <div :id="'allFilters_collapse_' + categoryNameKebab"
             class="accordion-collapse collapse"
             :data-category-group-handle="filterGroupHandle"
             :aria-labelledby="'allFilters_heading_' + categoryNameKebab"
             data-bs-parent="#allFilters">
            <div class="accordion-body">
                <div class="filter--custom" v-if="filterType !== 'colors'">
                    <div class="form-group">
                        <fieldset>
                            <div class="form-check" v-for="(option, idx) in filterOptions">
                                <input class="form-check-input" type="checkbox"
                                       :id="filterRandomId + '_' + idx"
                                       :value="option.id"
                                       :checked="activeOptionsSet.has(option.id)">
                                <label class="form-check-label bttn bttn--outline"
                                       :for="filterRandomId + '_' + idx"
                                       @click.prevent="labelToggle(option)">
                                    <span>{{ option.title }}</span>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="filter--color" v-if="filterType === 'colors'">
                    <div class="form-group">
                        <fieldset>
                            <div class="form-check" v-for="(option, idx) in filterOptions"
                                 :style="{ '--color': option.colorCode || '#000000' }">
                                <input class="form-check-input" type="checkbox"
                                       :id="filterRandomId + '_' + idx"
                                       :value="option.id"
                                       :checked="activeOptionsSet.has(option.id)">
                                <label class="form-check-label"
                                       :for="filterRandomId + '_' + idx"
                                       @click.prevent="labelToggle(option)">
                                    <span class="label-icon"><span></span></span>
                                    <span class="label-text">{{ option.title }}</span>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineComponent, onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { kebab, randomString } from "../helpers";
import mitt from "mitt";
import { webshopFiltersMapping } from "../vue-apps";

defineComponent({
    name: "FilterPills"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const props = defineProps(['filterOptions', 'categoryName', 'filterName', 'filterType']);
const emit = defineEmits(['filterChange']);
const filterRandomId = randomString();
const categoryNameKebab = ref('product-filter__');
const filtersMapping = webshopFiltersMapping;
const filterGroupHandle = ref('');
const activeOptionsSet = ref(new Set());

onBeforeMount(() => {
    filterGroupHandle.value = filtersMapping[props.filterName];
    categoryNameKebab.value = kebab(props.categoryName);
});
onMounted(() => {
    emitter.on('selectedFiltersUpdated', selectedFiltersUpdated);
});
onUnmounted(() => {
    emitter.off('selectedFiltersUpdated', selectedFiltersUpdated);
});

const emitFilterChange = () => {
    emit('filterChange', { filterName: props.filterName, selectedOptions: [... activeOptionsSet.value] });
};

const labelToggle = option => {
    if (activeOptionsSet.value.has(option.id)) {
        activeOptionsSet.value.delete(option.id);
    } else {
        activeOptionsSet.value.add(option.id);
    }
    emitFilterChange();
};

const selectedFiltersUpdated = selectedFilters => {
    activeOptionsSet.value.clear();
    if (selectedFilters[props.filterName] && selectedFilters[props.filterName].length) {
        for (const id of selectedFilters[props.filterName]) {
            activeOptionsSet.value.add(id);
        }
    }
    emitFilterChange();
};

</script>
