import {
    createPostRequest,
    createGetRequest,
    addDynamicEventListener,
    getSpinner,
    updateCartIndicator
} from "./helpers";
import {
    GTMAddItemToCart, GTMBeginCheckout,
    GTMRemoveItemFromCart,
} from "./analytics";
export function initCart() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

async function init() {
    const cartContainer = document.querySelector('.block__shop__cart');

    if( cartContainer ) {
        addDynamicEventListener(cartContainer, 'click', '.line-item-delete', (e) => removeItemFromCart(e) );
        addDynamicEventListener(cartContainer, 'click', 'button.qty-add', (e) => updateItemQty(e) );
        addDynamicEventListener(cartContainer, 'click', 'button.qty-sub', (e) => updateItemQty(e) );
        addDynamicEventListener(cartContainer, 'change', 'input.qty', (e) => updateItemQty(e) );
        addDynamicEventListener(cartContainer, 'click', '.bttn--discount', (e) => applyDiscount(e) );
        addDynamicEventListener(cartContainer, 'click', '.bttn.to-payment', (e) => beginCheckout(e) );
        
        // storing current value to be able to track the qty change
        const qtyInputs = cartContainer.querySelectorAll('input.qty');
        if( qtyInputs.length ) {
            qtyInputs.forEach(input => {
                input.oldValue = parseInt(input.value);
                //input.addEventListener('focus', (e) => e.target.oldValue = parseInt(e.target.value ?? 0));
            });
        }

        // TODO fix this later
        // Webshop Quantity Selector
        // Add button click event
        $('.qty-add').click(function () {
            const quantityInput = $(this).siblings('.qty');
            const quantity = parseInt(quantityInput.val());
            const maxQty =  quantityInput.attr('max');
            if( typeof maxQty !== 'undefined' && maxQty !== false ) {
                if( quantity + 1 > parseInt(maxQty) ) {
                    return;
                }
            }
            quantityInput.val(quantity + 1);
        });

        // Remove button click event
        $('.qty-sub').click(function () {
            const quantityInput = $(this).siblings('.qty');
            const quantity = parseInt(quantityInput.val());
            if (quantity > 0) {
                quantityInput.val(quantity - 1);
            }
        });
    }
}

const beginCheckout = async(event) => {
    event.preventDefault();
    event.stopPropagation();
    await GTMBeginCheckout();
    window.location.assign(event.target.href);
}

const applyDiscount = async (event) => {
    event.preventDefault();
    let couponCode = false;
    const discountInput = document.getElementById('cart__discount__input');
    if( discountInput instanceof HTMLElement ) {
        couponCode = discountInput.value;
    }
    
    if( couponCode ) {
        let payload = {couponCode, clearNotices: true};
        const response = await createPostRequest('/actions/commerce/cart/update-cart', payload);
        if( response.success ) {
            location.reload();
        } else {
            console.error(response.response);
        }
    }
}

const removeItem = async (lineItemId, listElement) => {
    const spinner = await getSpinner();
    spinner.show();
    
    if( lineItemId ) {
        const payload = {
            lineItems: {
                [lineItemId]: {remove: true}
            },
        }

        const response = await createPostRequest('/actions/commerce/cart/update-cart', payload);
        if( response.success ) {
            if( listElement instanceof HTMLElement ) {
                // GTM remove_from_cart
                const qtyInput = listElement.querySelector('input.qty');
                const purchasableId = listElement.dataset.purchasableId ?? null;
                if( qtyInput instanceof HTMLInputElement && purchasableId !== null ) {
                    const qty = parseInt(qtyInput.value);
                    await GTMRemoveItemFromCart(purchasableId, qty);
                }
                listElement.remove();
            }
            if ( response.response.cart.lineItems.length ) {
                updateCartIndicator();
                await updateCartInfo();
            } else {
                location.reload();
            }
        }
    }
    spinner.hide();
}

const removeItemFromCart = async (e) => {
    e.preventDefault();
    const lineItemId = parseInt(e.target.dataset.id);
    await removeItem(lineItemId, e.target.closest('.listelement'));
}

const updateItemQty = async (e) => {
    const input = e.target.parentNode.querySelector('input.qty');
    if( input ) {
        const qty = parseInt(input.value);
        const change = qty - input.oldValue;
        input.oldValue = qty;
        const lineItemId = parseInt(input.dataset.id);
        const listElement = input.closest('.listelement');
        
        if( lineItemId ) {
            if( listElement instanceof HTMLElement ) {
                const purchasableId = listElement.dataset.purchasableId ?? null;
                if( purchasableId !== null ) {
                    // GTM remove_from_cart / add_to_cart
                    if( change > 0 ) {
                        await GTMAddItemToCart(purchasableId, change);
                    } else {
                        await GTMRemoveItemFromCart(purchasableId, change * -1);
                    }
                }
            }
            
            if (qty > 0) {
                const spinner = await getSpinner();
                spinner.show();
                const payload = {
                    lineItems: {
                        [lineItemId]: {qty}
                    },
                }

                const response = await createPostRequest('/actions/commerce/cart/update-cart', payload);

                if (response.success) {
                    updateCartIndicator();
                    await updateCartInfo();
                } else {
                    console.error(response);
                }
                spinner.hide();
            } else {
                await removeItem(lineItemId, input.closest('.listelement'));
            }
        }
    }
}

const updateCartInfo = async () => {
    const response = await createGetRequest('/webshop/get-cart-info');
    if( response.success ) {
        const cartInfoContainer = document.getElementById('cart-info');
        if( cartInfoContainer && response.data.html ) {
            cartInfoContainer.parentNode.innerHTML = response.data.html;
        }
    } else {
        console.error(response);
    }
}
