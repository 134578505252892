export function initVideos() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {

    const youTubeIframeReady = new Promise(function(resolve){
        document.addEventListener('onYouTubeIframeAPIReady', resolve);
    });
    window.onYouTubeIframeAPIReady = function() {
        document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady', {}));
    }

    const youtubeMap = new Map();
    const vimeoMap = new Map();
    
    const videoBlocks = document.querySelectorAll('.block.block__video');
    for (const videoBlock of videoBlocks) {
        const playButton =  videoBlock.querySelector('.play-button');
        
        const video = videoBlock.querySelector('video');
        if (video) {
            const placeholderImage = playButton.parentElement.querySelector('img.video-placeholder');
            video.addEventListener('play', function () {
                video.classList.add('playing');
            });
            video.addEventListener('pause', function () {
                video.classList.remove('playing');
            });
            if (playButton) {
                playButton.addEventListener('click', function() {
                    const videoElement = playButton.parentElement.querySelector('video');
                    videoElement.classList.remove('d-none');
                    videoElement.controls = true;
                    if (placeholderImage) {
                        placeholderImage.classList.add('d-none');
                    }
                    playButton.classList.add('d-none');
                    video.play();
                });
            }
            if (! placeholderImage) {
                video.classList.remove('d-none');
            }
        }

        const youtubeEmbedPlaceholder = videoBlock.querySelector('[data-youtube-id]');
        if (youtubeEmbedPlaceholder) {
            const placeholderImage = videoBlock.querySelector('img.yt-placeholder');
            
            if (playButton) {
                playButton.addEventListener('click', function() {
                    const iframeElement = videoBlock.querySelector('iframe');
                    iframeElement.classList.remove('d-none');
                    if (placeholderImage) {
                        placeholderImage.classList.add('d-none');
                    }
                    playButton.classList.add('d-none');
                    const youtubeEventTarget = youtubeMap.get(iframeElement.dataset.youtubeId);
                    if (youtubeEventTarget) {
                        youtubeEventTarget.playVideo();
                    }
                });
            }

            const youtubeId = youtubeEmbedPlaceholder.dataset.youtubeId;
            const placeholderId = youtubeEmbedPlaceholder.id;
            youTubeIframeReady.then(() => {
                new YT.Player(placeholderId, {
                    videoId: youtubeId,
                    payerVars: {
                        playsinline: 1
                    },
                    events: {
                        onReady: (event) => {
                            youtubeMap.set(event.target.getVideoData().video_id, event.target);
                            const iframeElement = videoBlock.querySelector('iframe');
                            if (! placeholderImage && iframeElement) {
                                iframeElement.classList.remove('d-none');
                            }
                        },
                        onStateChange: ({data}) => {
                            if (data === 1 && playButton) {
                                playButton.classList.add('d-none');
                            }
                        }
                    }
                });
            });
        }
        
        const vimeoEmbedPlaceholder = videoBlock.querySelector('[data-vimeo-id]');
        if (vimeoEmbedPlaceholder) {
            setTimeout(() => initVimeoVideo(playButton.parentElement, vimeoMap), 0);
            const placeholderImage = videoBlock.querySelector('img.vimeo-placeholder');
            if (playButton) {
                playButton.addEventListener('click', function() {
                    const iframeWrapper = playButton.parentElement.querySelector('iframe').parentElement;
                    iframeWrapper.classList.remove('d-none');
                    if (placeholderImage) {
                        placeholderImage.classList.add('d-none');
                    }
                    playButton.classList.add('d-none');
                    vimeoMap.get(iframeWrapper.id).play();
                });
            }
        }
    }
}

function initVimeoVideo(wrapperElement, vimeoMap) {
    if (! window.Vimeo) {
        setTimeout(() => initVimeoVideo(wrapperElement, vimeoMap), 200);
        return;
    }
    const playerWrapper = wrapperElement.querySelector('.vimeoPlayer');
    if (vimeoMap.has(playerWrapper.id)) {
        return;
    }
    const videoPlayer = new Vimeo.Player(playerWrapper.id);
    vimeoMap.set(playerWrapper.id, videoPlayer);
    const placeholderImage = wrapperElement.querySelector('img.vimeo-placeholder');
    if (! placeholderImage) {
        playerWrapper.classList.remove('d-none');
    }
    videoPlayer.on('play', data => {
        const playButton =  wrapperElement.querySelector('.play-button');
        if (playButton) {
            playButton.classList.add('d-none');
        }
    });
}