import {GTMViewItemList} from "./analytics";

export function initProducers() {
    document.addEventListener('DOMContentLoaded', () => {
        const container = document.querySelector('.block__list__manufacturers');
        if( container instanceof HTMLElement ) {
            initIndex();
        }
        const producerContainer = document.querySelector('.block__manufacturer');
        if( producerContainer instanceof HTMLElement ) {
            initEntry()
        }
    });
}

function initIndex() {
    const navLetters = document.querySelector('ul.nav-letters');
    const producers = document.querySelectorAll('.listelement[data-letter]');
    let selectedLetter = null;
    
    if( navLetters instanceof HTMLElement ) {
        [...navLetters.querySelectorAll('a.sortable')].map(a => {
           a.addEventListener('click', (e) => {
               e.preventDefault();
               e.stopPropagation();
               selectedLetter = a.dataset.letter;
               filterProducers();
            }); 
        });
    }
    
    const filterProducers = () => {
        [...producers].map(producer => {
            const isVisible = selectedLetter === '*' || selectedLetter === producer.dataset.letter;
            if( isVisible ) {
                producer.classList.remove('d-none');
            } else {
                producer.classList.add('d-none');
            }
        });
    }
}

async function initEntry() {
    const productList = document.querySelector('.block__product__list');
    if( productList instanceof HTMLElement ) {
        const categoryTitle = productList.dataset.categoryTitle ?? 'No category title found';
        const items = productList.querySelectorAll('article');
        if( items.length ) {
            let purchasableIds = [];
            items.forEach(article => purchasableIds.push(article.dataset.purchasableId ?? null));
            purchasableIds = purchasableIds.filter(v => v !== null);
            if( purchasableIds.length ) {
                // GTM view_item_list
                await GTMViewItemList('producer_page', `Producer page ${categoryTitle}`, purchasableIds);
            }
        }
    }
}