import {
    addDynamicEventListener, createElement,
    createGetRequest,
    createModal, createModalFromTemplate, createPopover,
    createPostRequest,
    getClosest, postFormData, updateCartIndicator, updateWishlistIndicator
} from "./helpers";
import { Modal } from "bootstrap";

export function initWishlist() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

let selectWishlistModalBS;
let deleteWishlistModalBS;
let currentElementId;

async function init() {
    const selectWishlistModal = document.getElementById('modal__selectWishlist');
    const newWishlistModal = document.getElementById('modal__newWishlist');
    const deleteWishlistModal = document.getElementById('modal__deleteWishlist');
    
    if( newWishlistModal instanceof HTMLElement ) {
        newWishlistModal.addEventListener('submit', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const createListResponse = await createList(e.target);
            if( createListResponse !== false && createListResponse.success ) {
                newWishlistModal.querySelector('.bttn.bttn--close').click();
                newWishlistModal.querySelector('form').classList.remove('was-validated');
                newWishlistModal.querySelector('#modalForm__1__input__1').value = '';
                newWishlistModal.querySelector('#modalForm__1__input__2').value = '';

                const addListItemResponse = await addItemToList(createListResponse.response.id, newWishlistModal.dataset.elementId);
                if( addListItemResponse !== false && addListItemResponse.success ) {
                    const lastClickedAnchor = document.querySelector(`a.add-to-favorites[data-id="${newWishlistModal.dataset.elementId}"]`);
                    if( lastClickedAnchor instanceof HTMLElement ) {
                        lastClickedAnchor.classList.add('active');
                    }
                    await updateWishlistIndicator();
                } else {
                    console.error(addListItemResponse);
                }
            } else {
                console.error(createListResponse);
            }
        });
    }
    
    if( selectWishlistModal instanceof HTMLElement ) {
        selectWishlistModalBS = new Modal(selectWishlistModal);
        
        selectWishlistModal.addEventListener('show.bs.modal', async (e) => {
            const lists = await getLists();
            const selectInput = selectWishlistModal.querySelector('.wishlist-select-input');
            if( selectInput instanceof HTMLSelectElement ) {
                while (selectInput.options.length > 0) {
                    selectInput.remove(0);
                }
                
                if( lists.data.length ) {
                    for( const listData of lists.data ) {
                        const option = document.createElement('option');
                        option.value = listData.id;
                        option.text = listData.title;
                        selectInput.appendChild(option);
                    }
                }
            }
        });
        
        selectWishlistModal.addEventListener('submit', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            
            const selectedListOption = e.target.querySelector('select option:checked');
            if( selectedListOption instanceof HTMLOptionElement ) {
                const addListItemResponse = await addItemToList(selectedListOption.value, selectWishlistModal.dataset.elementId);
                if( addListItemResponse !== false && addListItemResponse.success ) {
                    selectWishlistModal.querySelector('.bttn.bttn--close').click();
                    const lastClickedAnchor = document.querySelector(`a.add-to-favorites[data-id="${newWishlistModal.dataset.elementId}"]`);
                    if( lastClickedAnchor instanceof HTMLElement ) {
                        lastClickedAnchor.classList.add('active');
                    }
                    await updateWishlistIndicator();
                } else {
                    console.error(addListItemResponse);
                }
            }
        });
    }
    
    if( deleteWishlistModal instanceof HTMLElement ) {
        deleteWishlistModalBS = new Modal(deleteWishlistModal);
        const deleteBtn = deleteWishlistModal.querySelector('button.delete');
        if( deleteBtn instanceof HTMLElement ) {
            deleteBtn.addEventListener('click', async (e) => {
                const deleteResponse = await deleteWishlist(deleteWishlistModal.dataset.wishlistId);
                if( deleteResponse.success && !deleteResponse.response.hasOwnProperty('error') ) {
                    const deleteWishlistBtn = document.querySelector(`a.delete-wishlist-modal[data-wishlist-id="${deleteWishlistModal.dataset.wishlistId}"]`);
                    if( deleteWishlistBtn instanceof HTMLElement ) {
                        const listelement = getClosest(deleteWishlistBtn, '.listelement');
                        if( listelement instanceof HTMLElement ) {
                            listelement.remove();
                        }
                    }
                    deleteWishlistModalBS.hide();
                    await updateWishlistIndicator();
                } else {
                    console.error(deleteResponse);
                }
            });
        }
        
        addDynamicEventListener(document, 'click', 'a.delete-wishlist-modal', (e) => {
            const anchor = getClosest(e.target, 'a.delete-wishlist-modal');
            if( anchor instanceof HTMLAnchorElement ) {
                e.preventDefault();
                e.stopPropagation();
                deleteWishlistModal.dataset.wishlistId = anchor.dataset.wishlistId;
                deleteWishlistModalBS.show();
            }
        });
    }
    
    // product list wishlist add / remove list action
    addDynamicEventListener(document, 'click', 'a.add-to-favorites', async (e) => {
        e.preventDefault();
        e.stopPropagation();
                
        const anchor = getClosest(e.target, 'a.add-to-favorites');
        if( anchor instanceof HTMLElement) {
            
            // const isLoggedIn = anchor.dataset.loggedIn === '1';
            // if( !isLoggedIn ) {
            //     const redirectUrl = anchor.dataset.redirectUrl;
            //     if( redirectUrl ) {
            //         return window.location.assign(redirectUrl);
            //     }
            // }
            
            currentElementId = anchor.dataset.id;
            if( anchor.classList.contains('active') ) {
                const removeItemResponse = await removeItemFromList(currentElementId);
                if( removeItemResponse.success && !removeItemResponse.response.hasOwnProperty('error') ) {
                    anchor.classList.remove('active');
                    const productContainer = getClosest(anchor, 'article');
                    const canBeRemoved = productContainer.dataset.hasOwnProperty('canBeRemoved') && productContainer.dataset.canBeRemoved === '1';
                    if( productContainer instanceof HTMLElement && canBeRemoved ) {
                        productContainer.remove();
                    }
                    await updateWishlistIndicator();
                } else {
                    console.error(removeItemResponse);
                }
            } else {
                newWishlistModal.dataset.elementId = currentElementId;
                selectWishlistModal.dataset.elementId = currentElementId;
                selectWishlistModalBS.show();
            }
        }
    });
    
    // copy list link to clipboard
    addDynamicEventListener(document, 'click', 'a[id^="copyLink"]', async (e) => {
       const anchor = getClosest(e.target, 'a[id^="copyLink"]');
       if( anchor instanceof HTMLAnchorElement ) {
           const popover = createPopover(anchor);
           popover.show();
           
           if( navigator.clipboard && window.isSecureContext ) {
               await navigator.clipboard.writeText(anchor.dataset.link);
           } else {
               console.error('Can not copy to clipboard on unsecure origins.');
           }           
       }
    });
    
    // add wishlist items to cart
    addDynamicEventListener(document, 'click', 'div.listelement__addToCart > a.bttn', async (e) => {
        const anchor = e.target.nodeName === 'A' ? e.target : getClosest(e.target, 'div.listelement__addToCart > a.bttn');
        if( anchor instanceof HTMLAnchorElement )  {
            e.preventDefault();
            e.stopPropagation();
            const addToCartResponse = await addItemsToCart(JSON.parse(anchor.dataset.variationIds));
            const popover = createPopover(anchor, 1500, true);

            if( addToCartResponse.response.hasOwnProperty('error') && addToCartResponse.response.hasOwnProperty('message') ) {
                popover.setContent({
                    '.popover-body': `Error: ${addToCartResponse.response.message}`
                });
                popover.enable();
                popover.show();
            } else {
                popover.setContent({
                    '.popover-body': anchor.dataset.bsContent
                });
                popover.enable();
                popover.show();

                await updateCartIndicator();
            }
        }
    });
    
    // Send email about wishlist
    addDynamicEventListener(document, 'click', '.listelement__getPrice > button', async(e) => {
        e.preventDefault();
        e.stopPropagation();
        const button = e.target.nodeName === 'BUTTON' ? e.target : getClosest(e.target, '.listelement__getPrice > button');
        if( button instanceof HTMLButtonElement ) {
            const wishlistId = button.dataset.wishlistId;
            const modal = await createModalFromTemplate('components/mypage/modals/_modal_wishlist_getPrice');
            if( modal instanceof Modal ) {
                modal.show();
                const wishlistForm = modal._element.querySelector('form');
                if( wishlistForm instanceof HTMLFormElement ) {
                    wishlistForm.addEventListener('submit', async(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const emailResponse = await createPostRequest('/email/send-wishlist-email', {wishlistId});
                        modal.hide();
                        if( !emailResponse.response.success ) {
                            const errorModal = await createModal(
                                'Error',
                                emailResponse.response.hasOwnProperty('error') ? emailResponse.response.error : 'Det oppsto en feil mens vi prøvde å komme i kontakt med Lindbak kundeservice. <br> Prøv igjen senere.', 'OK');
                            if( modal instanceof Modal) {
                                errorModal.show();
                            }
                        }
                    });
                }
            }
        }
    });

    // event handlers for edit meta forms
    addDynamicEventListener(document, 'submit', 'form.edit-wishlist-meta', async(e) => {
        e.preventDefault();
        e.stopPropagation();
        const updateResponse = await updateWishlist(e.target);
        if( updateResponse.response.success ) {
            const title = e.target.querySelector('input[name="title"]');
            if( title instanceof HTMLInputElement ) {
                const listElement = getClosest(e.target, '.listelement');
                if( listElement instanceof HTMLElement ) {
                    const heading = listElement.querySelector('h3.wishlist-title');
                    if( heading instanceof HTMLHeadingElement ) {
                        heading.textContent = title.value;
                    }

                }
            }
        }
    });
    
    const newWishlistToggler = document.querySelector('.toggle-new-wishlist-modal');
    if( newWishlistToggler instanceof HTMLButtonElement ) {
        newWishlistToggler.addEventListener('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const modal = await createModalFromTemplate('components/mypage/modals/_modal_wishlist_new');
            if( modal instanceof Modal ) {
                modal.show();
                const createForm = modal._element.querySelector('form');
                if( createForm instanceof HTMLFormElement ) {
                    createForm.addEventListener('submit', async(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const createResponse = await postFormData('/actions/wishlist/lists/create', createForm);
                        modal.hide();
                        if( createResponse.response.success ) {
                            const listId = createResponse.response.id;
                            const listItemTemplateResponse = await getWishlistItemTemplate(listId); 
                            if( listItemTemplateResponse.response.success ) {
                                const newWishlistElement = createElement(listItemTemplateResponse.response.html);
                                const wishlistsContainer = document.getElementById('wishlists');
                                if( wishlistsContainer instanceof HTMLElement ) {
                                    wishlistsContainer.appendChild(newWishlistElement);
                                }
                            }
                        } else {
                            const errorModal = createModal(
                                'Error',
                                'Det oppsto en feil mens vi prøvde å lage ønskelisteskjemaet', 'OK');
                            if( modal instanceof Modal) {
                                errorModal.show();
                            }
                        }
                    });
                }
            }
        })
    }
    
    const logoutButton = document.querySelector('.bttn--logOut');
    if( logoutButton instanceof HTMLAnchorElement ) {
        logoutButton.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const logoutModal = createModal(
                'Logg ut',
                'Er du sikker på at du vil logge ut fra nettsiden?', 'Ja', 'Nei');
            if( logoutModal instanceof Modal) {
                logoutModal.show();
                const confirmLogoutButton = logoutModal._element.querySelector('button[type="submit"]');
                if( confirmLogoutButton instanceof HTMLButtonElement ) {
                    confirmLogoutButton.addEventListener('click', (e) => {
                        window.location.assign(logoutButton.href);
                    });
                }
            }
        })
    }
}

const createList = async (form) => {
    try {
        return await createPostRequest('/actions/wishlist/lists/create', Object.fromEntries(new FormData(form)));
    } catch (e) {
        console.error(e);
    }
    return false;
};
const getLists = async () => {
    return await createGetRequest('/webshop/get-wishlists');
};

const addItemToList = async(listId, elementId) => {
    try {
        return await createPostRequest('/actions/wishlist/items/add', {elementId, listId});
    } catch (e) {
        console.error(e);
    }
    return false;  
};

const removeItemFromList = async(elementId) => {
    try {
        const wishlistIdResponse = await getListIdForWishlistItem(elementId);
        if( wishlistIdResponse.success ) {
           return await createPostRequest('/actions/wishlist/items/remove', {elementId, listId: wishlistIdResponse.response.listId});
        }
    } catch (e) {
        console.error(e);
    }
    return false;
};

const getListIdForWishlistItem = async(elementId) => {
    return await createPostRequest('/webshop/get-wishlist-id-for-wishlist-item', {elementId});
}

const deleteWishlist = async(listId) => {
  return await createPostRequest('/actions/wishlist/lists/delete', {listId});  
};

const addItemsToCart = async(variationIds = []) => {
    const payload = {
        purchasables: []
    };
    variationIds.map((id, index) => {
        payload.purchasables[index] = {
            'id': id,
            'qty': 1,
            'note': 'From wishlist'
        };
    });
    return await createPostRequest('/actions/commerce/cart/update-cart', payload);
};

const updateWishlist = async(form) => {
    return await postFormData('/actions/wishlist/lists/update', form);
}

const getWishlistItemTemplate = async(listId) => {
    try  {
        return await createPostRequest('/webshop/get-wishlist-item-template', {listId});
    } catch (e) {
        console.error(e);
    }
}