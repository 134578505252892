<template>
    <template v-if="products.length > 0">
        <div class="list__stats">
            {{ listStats }}
        </div>
        <div v-if="products.length < totalProducts" class="list__pagination">
            <a class="bttn bttn--solid color--color4"
               href="#"
               @click.prevent="emitLoadMore">
                {{ translations.showMore }}
            </a>
        </div>
    </template>
</template>

<script setup>
import { computed, defineComponent } from 'vue';

defineComponent({
    name: "WebshopFooter"
});

const props = defineProps(['products', 'totalProducts']);
const emit = defineEmits(['loadMore']);
const translations = window.lindbakVueTranslations;

const listStats = computed(() => {
    return translations.showingNumberOfTotalProducts
        .replace('{number}', props.products.length)
        .replace('{total}', props.totalProducts);
});

const emitLoadMore = () => {
    emit('loadMore');
}

</script>