export function initBootstrapFormValidationStyles() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
            evaluateFormControlValidity(form.querySelectorAll('input, select, textarea'));
        }, false);

        // Add event listeners to evaluate control validity on input change
        form.querySelectorAll('input, select, textarea').forEach(control => {
            control.addEventListener('input', () => {
                evaluateFormControlValidity([control]);
            });
        });
    });
}

function evaluateFormControlValidity(controls) {
    controls.forEach(control => {
        const formGroup = control.closest('.form-group');
        if( formGroup ) {
            const validationContainer = formGroup.querySelector('.form-group__validation');
            const validFeedback = validationContainer?.querySelector('.valid-feedback');
            const invalidFeedback = validationContainer?.querySelector('.invalid-feedback');
            if (control.id === 'form__business__input__billingCompanyName') {
                return;
            }
            if (control.checkValidity()) {
                control.classList.add('is-valid');
                control.classList.remove('is-invalid');

                if (validFeedback) validFeedback.style.display = 'block';
                if (invalidFeedback) invalidFeedback.style.display = 'none';  // Hide invalid feedback
            } else {
                control.classList.add('is-invalid');
                control.classList.remove('is-valid');

                if (validFeedback) validFeedback.style.display = 'none';  // Hide valid feedback
                if (invalidFeedback) invalidFeedback.style.display = 'block';
            }
        }

    });
}
