<template>
    <article v-for="ids in productVariantIdsToShow"
             class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 listelement"
             data-can-be-removed="0" :data-purchasable-id="getPurchasableId(ids)">
        <ProductListItem :productData="products[ids.join('-')]" :ids="ids"></ProductListItem>
    </article>
    <div class="col-12 d-flex justify-content-center" v-if="loadingMore">
        <div class="spinner-border my-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script setup>

import { computed, defineComponent, onMounted, ref } from 'vue';
import ProductListItem from "./ProductListItem.vue";

defineComponent({
    name: "ProductGrid"
});

const props = defineProps(['productVariantIds', 'loadMore']);

const products = ref({});
let loadingMore = ref(props.loadMore || false);
const productVariantIdsToShow = computed(() => {
    if (! loadingMore.value) {
        return props.productVariantIds;
    } else {
        return props.productVariantIds.slice(0, 24);
    }
});

onMounted(() => {
    fetchProductData();
});

const fetchProductData = async () => {
    try {
        const response = await fetch('/vue/get-product-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productVariantIds: productVariantIdsToShow.value })
        });
        const data = await response.json();
        if (data.success) {
            props.productVariantIds.forEach(ids => {
                const key = ids.join('-');
                products.value[key] = data.productData.find(product => product.productId === ids[0] && (null === ids[1] || product.variantId === ids[1]));
            });
        }
    } catch (error) {
        console.error('Error fetching product data:', error);
    }
    if (loadingMore.value) {
        const response = await fetch('/vue/get-product-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productVariantIds: props.productVariantIds.slice(24) })
        });
        const data = await response.json();
        if (data.success) {
            props.productVariantIds.forEach(ids => {
                const key = ids.join('-');
                if (! products.value[key]) {
                    products.value[key] = data.productData.find(product => product.productId === ids[0] && (null === ids[1] || product.variantId === ids[1]));
                }
            });
        }
        loadingMore.value = false;
    }
};

const getClasses = (ids) => {
    const product = products.value[ids.join('-')];
    return product ? [ product.stockClass, product.priceClass ] : null;
}

const getPurchasableId = (ids) => {
    const product = products.value[ids.join('-')];
    return product ? product.purchasableId : null;
};

</script>
