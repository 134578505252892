<template>
    <div class="block__header__tools">
        {{ productCountLabel }}
        <div class="skeleton-search" v-if="loading"></div>
        <div class="dropdown" v-if="! loading && totalProducts > 0">
            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ selectedSortOption.name }}
            </button>
            <ul class="dropdown-menu">
                <template v-for="sortOption in sortOptions">
                    <li v-if="sortOption.value !== selectedSortOption.value">
                        <a class="dropdown-item sort-filter"
                           href="#"
                           data-sort="{{ sortOption.value }}"
                           @click.prevent="emitSortChange(sortOption)"
                        >
                        {{ sortOption.name }}
                        </a>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, defineComponent, onBeforeMount, ref } from 'vue';

defineComponent({
    name: "WebshopHeaderSort"
});

const props = defineProps(['products', 'totalProducts', 'initialSort', 'loading']);
const emit = defineEmits(['sortChange']);
const translations = window.lindbakVueTranslations;

const sortOptions = [
    { name: translations.sortOptions.standard, value: 'standard:asc' },
    { name: translations.sortOptions.name, value: 'name:asc' },
    { name: translations.sortOptions.priceDesc, value: 'price:desc' },
    { name: translations.sortOptions.priceAsc, value: 'price:asc' },
    { name: translations.sortOptions.stockDesc, value: 'stock:desc' },
    { name: translations.sortOptions.stockAsc, value: 'stock:asc' },
];
const selectedSortOption = ref(sortOptions[0]);

const productCountLabel = computed(() => {
    return props.loading || props.totalProducts === 0 ? '' : translations.numberOfTotalProductsSortedBy
        .replace('{number}', props.products.length)
        .replace('{total}', props.totalProducts);
});

onBeforeMount(() => {
    if (props.initialSort) {
        for (const sortOption of sortOptions) {
            if (sortOption.value === props.initialSort) {
                selectedSortOption.value = sortOption;
                break;
            }
        }
    }
});

const emitSortChange = sortOption => {
    selectedSortOption.value = sortOption;
    emit('sortChange', sortOption.value);
}

</script>

<style scoped>
.skeleton-search {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 0.125rem;
    height: 1.25rem;
    width: 20rem;
    max-width: 100%;
}
@keyframes skeleton-loading {
    0% {
        background-color: #fcfcfc;
    }
    100% {
        background-color: #ebebeb;
    }
}

</style>
