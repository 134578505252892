<template>
    <div class="range-slider" :class="`range-slider--` + filterKey">
        <div class="range-slider__title">{{ title }}</div>
        <div ref="sliderRef"
             :data-visible-name="title"
             :data-max-value="minMaxObj.max"
             :data-filter-name="filterKey"
             :data-min-selected-value="selectedMinMaxObj.min || 0"
             :data-max-selected-value="selectedMinMaxObj.max || maxValue">
        </div>
        <div class="labels">
            <div class="range-slider__min" ref="sliderMinRef"></div>
            <div class="range-slider__max" ref="sliderMaxRef"></div>
        </div>
    </div>
</template>

<script setup>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import noUiSlider from "nouislider";
import mitt from "mitt";

defineComponent({
    name: "FilterSizeSlider"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const translations = window.lindbakVueTranslations;
const props = defineProps(['minMaxObj', 'filterKey', 'title', 'maxValue']);
const emit = defineEmits(['valueUpdate']);
const selectedMinMaxObj = ref({min: 0, max: props.maxValue});
const sliderRef = ref(null);
const sliderMinRef = ref(null);
const sliderMaxRef = ref(null);
let originalMinMaxObj = null;

onMounted(() => {
    initSlider();
    originalMinMaxObj = originalMinMaxObj || props.minMaxObj;
    emitter.on('selectedSizesUpdated', selectedSizesUpdated);
});
onUnmounted(() => {
    emitter.off('selectedSizesUpdated', selectedSizesUpdated);
});

const initSlider = () => {
    noUiSlider.create(sliderRef.value, {
        start: [selectedMinMaxObj.value.min || 0, selectedMinMaxObj.value.max || props.maxValue],
        connect: true,
        range: {
            'min': 0,
            'max': props.minMaxObj.max
        },
        step: 1,
    });
    sliderRef.value.noUiSlider.on('update', function (values) {
        sliderMinRef.value.innerHTML = Math.round(values[0]) + " cm";
        sliderMaxRef.value.innerHTML = Math.round(values[1]) + " cm";
    });
    sliderRef.value.noUiSlider.on('end', async (values) => {
        emit('valueUpdate', {
            key: props.filterKey, value: {
                min: parseInt(values[0]),
                max: parseInt(values[1])
            }
        });
    });
}

const selectedSizesUpdated = sizes => {
    if (sizes[props.filterKey]) {
        sliderRef.value.noUiSlider.set([sizes[props.filterKey].min, sizes[props.filterKey].max]);
    } else {
        sliderRef.value.noUiSlider.set([0, originalMinMaxObj.max]);
    }
};
</script>