import {createPostRequest} from "./helpers";

let showTaxSelector;
let hideTaxSelector;
export function initTaxSelector() {
    showTaxSelector = document.querySelector('.segment--priv > a');
    hideTaxSelector = document.querySelector('.segment--corp > a');
    
    if( showTaxSelector instanceof HTMLElement && hideTaxSelector instanceof HTMLElement ) {
        init();
    }
}

function init() {
    showTaxSelector.addEventListener('click', setTaxFieldOnUser);
    hideTaxSelector.addEventListener('click', setTaxFieldOnUser);
}

const setTaxFieldOnUser = async(event) => {
    event.preventDefault();
    event.stopPropagation();
    const element = event.target.parentNode;
    const showTax = element.classList.contains('segment--priv');
    if( !element.classList.contains('active') ) {
        const response = await createPostRequest('/webshop/update-tax-visibility', {showTax});
        if( response.response.success ) {
            updateSelectorActiveState(element);
            updateFrontendPrices(showTax);
        } else if( response.success.hasOwnProperty('error') ) {
            console.error(response.response.error);
        }
    }
}

const updateSelectorActiveState = (selectedElement) => {
    if( selectedElement instanceof HTMLElement ) {
        [...selectedElement.parentNode.children].map(element => element.classList.remove('active'));
        selectedElement.classList.add('active');
    }
};

const updateFrontendPrices = (showTax = true) => {
    const priceContainers = document.querySelectorAll('.product-price');
    if( priceContainers.length ) {
        [...priceContainers].map(pc => {
            const containerWithTax = pc.querySelector('div.withTax');
            const containerWithoutTax = pc.querySelector('div.withoutTax');
            if( containerWithTax instanceof HTMLElement && containerWithoutTax instanceof HTMLElement ) {
                if( showTax ) {
                    containerWithTax.classList.remove('d-none');
                    containerWithoutTax.classList.add('d-none');
                } else {
                    containerWithTax.classList.add('d-none');
                    containerWithoutTax.classList.remove('d-none');
                }
            }
        });
    }
}