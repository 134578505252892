import {getSpinner, showToast} from "./helpers";
import {StripeClient} from "./stripe";
import {GTMAddPaymentInfo} from "./analytics";

export function initCheckoutStep2() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

async function init() {
    const spinner = await getSpinner();
    const step2Container = document.querySelector('.checkoutStep--2');
    if( step2Container instanceof HTMLElement ) {
        const paymentGatewayContainer = document.querySelector('.block__shop__checkout__paymentGateway');
        const paymentForm = step2Container.querySelector('form#payment-form');
        const submitButton = document.querySelector('.block__shop__checkout__nav button[type="submit"]');
        
        const paymentGatewayHandle = paymentGatewayContainer.hasAttribute('data-payment-gateway-handle') ? paymentGatewayContainer.getAttribute('data-payment-gateway-handle') : false;
        
        if( paymentGatewayHandle && step2Container instanceof HTMLElement && paymentForm instanceof HTMLFormElement && submitButton instanceof HTMLElement ) {
            if( paymentGatewayHandle === 'stripe'  ) {
                const publishableKey = paymentGatewayContainer.hasAttribute('data-key') ? paymentGatewayContainer.getAttribute('data-key') : false;
                if( publishableKey ) {
                    const cardContainer = document.querySelector('.block__shop__checkout__paymentGateway__body');
                    const errorContainer = document.querySelector('.block__shop__checkout__paymentGateway__errors');
                    if( cardContainer instanceof HTMLElement && errorContainer instanceof HTMLElement ) {
                        // error handling doesn't seem to work with async functions
                        try {
                            const stripe = await StripeClient.initialize(publishableKey);
                            await stripe.mountFrontend(paymentForm, cardContainer, errorContainer);
                            submitButton.addEventListener('click', async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // Send GTM add_payment_info event
                                await GTMAddPaymentInfo('Credit Card - stripe');
                                await stripe.submitPayment();
                            });
                        } catch (error) {
                            showToast(error, 'Error', true);
                        }
                    }
                }
 
            } else if( paymentGatewayHandle === 'two' || paymentGatewayHandle  === 'legacyInvoice' ) {
                submitButton.addEventListener('click', async(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    spinner.show();
                    // Send GTM add_payment_info event
                    await GTMAddPaymentInfo(`Invoice - ${paymentGatewayHandle}`);
                    paymentForm.submit();
                });
            }
        }
    }
}