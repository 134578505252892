import {addDynamicEventListener, createPostRequest, getClosest, getSpinner} from "./helpers";

let mainContainer;
let selectedFilterInput;
let allFilterInputs;
export function initSearchLandingPage() {
    document.addEventListener('DOMContentLoaded', () => {
        mainContainer = document.querySelector('.search__landing__container');
        if( mainContainer instanceof HTMLElement ) {
            init();
        }
    });
}

async function init() {
    addDynamicEventListener(mainContainer, 'click', '.block__footer .load-more', e => loadMoreEntryHandler(e));
    addDynamicEventListener(mainContainer, 'change', '.filter--search input.form-check-input', e => filterContentTypes(e));
    selectedFilterInput = document.querySelector('input.content-type-filter-checkbox:checked');
    allFilterInputs = document.querySelectorAll('input.content-type-filter-checkbox');
    filterContentByInput(selectedFilterInput);
}

async function loadMoreEntryHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    const container = event.target.closest('.block__list');
    const searchInput = mainContainer.querySelector('#search-results-input');
    if( container instanceof HTMLElement && searchInput instanceof HTMLElement ) {
        const spinner = await getSpinner();
        spinner.show();
        const serverResponse = await createPostRequest('/sok/index', {index: container.dataset.indexName, searchTerm: searchInput.value});
        if( serverResponse.response.success && serverResponse.response.hasOwnProperty('html') ) {
            const tempDOM = (new DOMParser()).parseFromString(serverResponse.response.html, "text/html");
            const newContainer = tempDOM.querySelector('.block__list');
            if( newContainer instanceof HTMLElement ) {
                container.replaceWith(newContainer);
            } else {
                console.error("Could not parse server response.");
            }
        } else {
            console.error(serverResponse);
        }
        spinner.hide();
    }
}

function filterContentTypes(event) {
    filterContentByInput(event.target);
}

function filterContentByInput(input) {
    if( input instanceof HTMLInputElement ) {
        const allContentContainers = mainContainer.querySelectorAll('.block__list');
        if( !input.id.includes('all') ) {
            const contentContainer = mainContainer.querySelector(`.block__list[data-filter-id="${input.id}"]`);
            [...allContentContainers].map(container => {
                if( container.dataset.filterId === contentContainer.dataset.filterId ) {
                    container.classList.remove('d-none');
                } else {
                    container.classList.add('d-none');
                }
            });
        } else {
            [...allContentContainers].map(container => container.classList.remove('d-none'));
        }
    } else {
        console.error("Couldn't find input element.");
    }
}