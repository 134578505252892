<template>
    <div class="accordion" id="allFilters">
        <FilterCategories v-if="filters.productCategory"
                          :categories="filters.productCategory"
                          :filter-key="'productCategoryIds'"
                          @filter-change="filterChange">
        </FilterCategories>
        <FilterPills v-if="filters.producers"
                     :filter-options="filters.producers"
                     :category-name="translations.categoryNames.producers"
                     :filter-name="'producerIds'"
                     @filter-change="filterChange">
        </FilterPills>
        <FilterPills v-if="filters.areaOfUse"
                     :filter-options="filters.areaOfUse"
                     :category-name="translations.categoryNames.areaOfUse"
                     :filter-name="'areaOfUseIds'"
                     @filter-change="filterChange">
        </FilterPills>
        <FilterPills v-if="filters.colors"
                     :filter-options="filters.colors"
                     :category-name="translations.categoryNames.color"
                     :filter-name="'colorIds'"
                     :filter-type="'colors'"
                     @filter-change="filterChange">
        </FilterPills>
        <FilterSizes v-if="filters.sizes"
                     :sizes="filters.sizes"
                     :category-name="translations.categoryNames.size"
                     :filter-name="'size'"
                     @sizes-change="sizesChange">
        </FilterSizes>
        <FilterPills v-if="filters.environmentalAndQualityMarks"
                     :filter-options="filters.environmentalAndQualityMarks"
                     :category-name="translations.categoryNames.environmentalAndQualityMarks"
                     :filter-name="'environmentalDocumentationIds'"
                     @filter-change="filterChange">
        </FilterPills>
        <FilterPills v-if="filters.salesPitch"
                     :filter-options="filters.salesPitch"
                     :category-name="translations.categoryNames.salesPitch"
                     :filter-name="'salesPitchIds'"
                     @filter-change="filterChange">
        </FilterPills>
        <FilterPills v-if="filters.segment"
                     :filter-options="filters.segment"
                     :category-name="translations.categoryNames.segment"
                     :filter-name="'segmentIds'"
                     @filter-change="filterChange">
        </FilterPills>
    </div>
</template>

<script setup>
// TODO parse & set initial selection from URL

import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import mitt from 'mitt';
import FilterPills from "./FilterPills.vue";
import FilterSizes from "./FilterSizes.vue";
import FilterCategories from "./FilterCategories.vue";

defineComponent({
    name: "WebshopFilters"
});

const emitter = window.webshopEmitter || mitt();
window.webshopEmitter = emitter;
const translations = window.lindbakVueTranslations;
const isLoading = ref(true);
const filters = ref({});
const activeFilters = ref({});
const activeSizes = ref({});

const filtersUpdated = fs => {
    isLoading.value = false;
    const fsKeys = Object.keys(fs);
    for (const fsKey of fsKeys) {
        const accordionElem = document.querySelector(`#allFilters .accordion-collapse[data-category-group-handle="${fsKey}"]`);
        if (! accordionElem || ! accordionElem.classList.contains('show')) {
            filters.value[fsKey] = fs[fsKey];
        }
    }
};

onMounted(() => {
    emitter.on('filtersUpdated', filtersUpdated);
});
onUnmounted(() => {
    emitter.off('filtersUpdated', filtersUpdated);
});

const filterChange = ({ filterName, selectedOptions }) => {
    activeFilters.value[filterName] = selectedOptions;
    emitter.emit('activeFilterChange', activeFilters.value);
};
const sizesChange = selectedSizes => {
    activeSizes.value = selectedSizes;
    emitter.emit('activeSizesChange', activeSizes.value);
};
 
</script>